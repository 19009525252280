import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import daniatou from '../../../Images/daniatou.jpeg'
import soukaina from '../../../Images/soukaina.jpg'
import meriam from '../../../Images/meriam.jpg'
import OwlCarousel from 'react-owl-carousel';
export const EquipePermanente = () => {

    const options = {
        items: 3,
        margin: 40,
        nav: true,
        rewind: true,
        autoplay: false,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };

    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl font-semibold '>Equipe permanente</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className="carousels  p-12">
                <div className='grid grid-cols-1 md:grid-cols-3'>
                    <div className='mb-6  flex items-center justify-center flex-col'>
                        <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                            <img className='border-b mt-[-40px] ' src={daniatou} alt="membre bureau" />
                        </div>
                        <h3 className='text-lg font-bold text-center py-3 text-black'>Mlle. DANIATOU DJIBRIL</h3>
                        <span className='text-primary'>Dévéloppeur web</span>
                    </div>
                    <div className='mb-6 flex items-center justify-center flex-col'>
                        <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                            <img className='border-b' src={soukaina} alt="membre bureau" />
                        </div>
                        <h3 className='text-lg font-bold text-center py-3 text-black'>Mlle. SOUKAINA SOUKRAT</h3>
                        <span className='text-primary'>Administration & Finance</span>
                    </div>
                </div>


                {/* <OwlCarousel className='owl-theme py-5' loop {...options} nav> */}

                {/* <div className='flex items-center justify-center flex-col'>
                        <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                            <img className='border-b' src={meriam} alt="membre bureau" />
                        </div>
                        <h3 className='text-lg font-bold text-center py-3 text-black'>Mlle. MERYEM EL ARIBI</h3>
                        <span className='text-primary'>Assistante</span>
                    </div> */}
                {/* </OwlCarousel> */}
            </div>
            <Footer />
        </div>
    )
}
