import cafe from '../Images/affaires/cafe.jpg'
import cacao from '../Images/affaires/cacao.jpg'
import noixCajou from '../Images/affaires/noixCajou.jpg'
import banane from '../Images/affaires/banane.jpg'
import machineIndustrielle from '../Images/affaires/machineIndustrielle.jpg'
import ananas from '../Images/affaires/ananas.jpg'
import huile from '../Images/affaires/huile.jpg'
import arachide from '../Images/affaires/arachide.jpg'
import ble from '../Images/affaires/blé.jpg'
import riz from '../Images/affaires/riz.jpg'

import fournituresbureau from '../Images/affaires/fournitures-bureau.PNG'
import mobilierbureau from '../Images/affaires/mobiler-bureau.PNG'
import hitech from '../Images/affaires/hitech2.jpg'
import auto from '../Images/auto.jpg'
import ameublemt from '../Images/affaires/ameublement.PNG'


import hitechElectro from '../Images/boutiqueP/hitechElectro.jpg'
import beaute from '../Images/boutiqueP/beaute.PNG'
import presents from '../Images/boutiqueP/présents.PNG'
import poterie from '../Images/boutiqueP/poterie.PNG'
import billeterie from '../Images/boutiqueP/billeterie.PNG'





export const ProduitsApprovisionnementRepository = [
    {
        name: 'Noix de cajou',
        picture: noixCajou,

    },
    {
        name: 'Cacao',
        picture: cacao,
    },
    {
        name: 'Banane',
        picture: banane,
    },
    {
        name: 'Café',
        picture: cafe,
    },
    {
        name: 'Machines industrielles',
        picture: machineIndustrielle,
    },
    {
        name: 'Blé',
        picture: ble,
    },
    {
        name: 'Ananas',
        picture: ananas,
    },
    {
        name: 'Arachide',
        picture: arachide,
    },
    {
        name: 'Riz',
        picture: riz,
    },
    {
        name: 'Huile',
        picture: huile,
    },



]

export const ProduitsCentralesAchat = [
    {
        name: 'Fournitures de bureau',
        picture: fournituresbureau,
    },
    {
        name: 'Mobilier de bureau',
        picture: mobilierbureau,
    },
    {
        name: 'Matériel Hitech',
        picture: hitech,
    },
    {
        name: 'Ameublement',
        picture: ameublemt,
    },
    {
        name: 'Automobile multi-marque',
        picture: auto,
    },
]

export const ProduitsBoutiquepRepos = [
    {
        name: "Billeterie sportive",
        picture: billeterie
    },
    {
        name: "Matériel Hitech et Electroménager",
        picture: hitechElectro
    },
    {
        name: "Bien être et Beauté",
        picture: beaute
    },
    {
        name: "Présents protocolaires",
        picture: presents
    },
    {
        name: "Pôterie",
        picture: poterie
    },


]