import React from 'react'
import { EventsRepos } from '../../repositories/EventsRepo'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import Header from '../Common/Header';
import { Footer } from '../Common/Footer';
import { FaPlusCircle } from 'react-icons/fa'


export const Evenements = () => {

    const options = {
        items: 3,
        margin: 50,
        nav: true,
        rewind: true,
        autoplay: false,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }

    };

    const { category } = useParams();
    const [filteredRepository, setFilteredRepository] = useState(EventsRepos.filter(event => event.category === category));

    useEffect(() => {
        setFilteredRepository(EventsRepos.filter(event => event.category === category));
    }, [category]);

    if (filteredRepository.length === 0) {
        return (
            <div>
                <Header />
                <div>
                    <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                        <h1 className='text-lg  md:text-2xl  font-semibold uppercase '>{category} </h1>
                        <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
                    </div>
                    <div className=' m-12 border col-span-3 border-primary shadow-xl rounded-xl mb-10 p-7 text-lg text-center'>
                        <p>Début de session: Mars 2023</p>
                    </div>

                </div>
                <Footer />
            </div>
        );
    }
    else if (filteredRepository.length <= 3) {
        return (
            <div>
                <Header />
                <div>
                    <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                        <h1 className='text-lg  md:text-2xl  font-semibold uppercase '>{category} </h1>
                        <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-6 p-12 ">
                        {filteredRepository.map((event, index) => (
                            <div key={event.title + index} className=' bg-secondary shadow-xl pb-2 h-[250px] xl:h-[290px] relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='h-[70%] w-full object-cover ' src={event.picture} alt={event.title} />
                                <div className='absolute w-[30%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                                    {category === 'concours & tournois' ?
                                        (<p className='capitalize' >{event.specificCat}</p>)
                                        :
                                        (<p className='capitalize' >{event.category}</p>)
                                    }
                                    <br />
                                    <hr />
                                    <p className='text-4xl'>{event.jour}</p>
                                    <br />
                                    <p className='tracking-wide'>{event.mois}</p>
                                    <p>{event.annee}</p>
                                </div>
                                <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                                                bottom-0 left-[-100%]  z-30
                                                group-hover:left-0
                                                w-[100%]
                                                group-hover:transition-all
                                                group-hover:duration-300
                                                ">
                                    <div className='secteur-desc absolute my-6 mx-2 pb-3 text-white'> <p>{event.description}</p>
                                        <button className='btn-center ml-6 mt-[40%] bg-white text-black'>S'inscrire</button>
                                    </div>
                                </div>
                                <p className='float-right w-[70%] mt-3 text-black font-bold  text-center text-xs' >{event.title}
                                    <br />
                                    {
                                        category === 'formation' &&
                                        <div className='flex space-x-1 justify-center text-black' ><span className=''><FaPlusCircle size={16} className='' /> </span><span> Stage pratique garanti {event.advantage} </span></div>
                                    }
                                </p>

                            </div>
                        ))}
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else if (filteredRepository.length > 3) {
        return (
            <div>
                <Header />
                <div>
                    <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                        <h1 className='text-lg  md:text-2xl  font-semibold uppercase '>{category} </h1>
                        <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
                    </div>
                    <div className="carousels p-12">
                        <OwlCarousel className='owl-theme py-5' loop {...options} nav>
                            {filteredRepository.map((event, index) => (
                                <div key={event.title + index} className=' bg-secondary shadow-xl pb-2 h-[250px] xl:h-[290px] relative group group-overlay cursor-pointer overflow-hidden'>
                                    <img className='h-[70%] w-full object-cover ' src={event.picture} alt={event.title} />
                                    <div className='absolute w-[30%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                                        {category === 'concours & tournois' ?
                                            (<p className='capitalize' >{event.specificCat}</p>)
                                            :
                                            (<p className='capitalize' >{event.category}</p>)
                                        }
                                        <br />
                                        <hr />
                                        <p className='text-4xl'>{event.jour}</p>
                                        <br />
                                        <p className='tracking-wide'>{event.mois}</p>
                                        <p>{event.annee}</p>
                                    </div>
                                    <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                                                bottom-0 left-[-100%]  z-30
                                                group-hover:left-0
                                                w-[100%]
                                                group-hover:transition-all
                                                group-hover:duration-300
                                                ">
                                        <div className='secteur-desc absolute my-6 mx-2 pb-3 text-white'> <p>{event.description}</p>
                                            <button className='btn-center ml-6 mt-[40%] bg-white text-black'>S'inscrire</button>
                                        </div>
                                    </div>
                                    <p className='float-right w-[70%] mt-3 text-black font-bold  text-center text-xs' >{event.title}
                                        <br />
                                        {
                                            category === 'formation' &&
                                            <span className=' mr-10 ' > {event.advantage} </span>
                                        }
                                    </p>

                                </div>
                            ))
                            }
                        </OwlCarousel>
                    </div>
                </div >
                <Footer />
            </div >
        );
    }
}
