import './App.css';
import { Routes, Route } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Accueil } from './Components/pages/Accueil';
import { SignIn } from './Components/pages/SignIn';
import { CourtierEnCommerce } from './Components/pages/activites/CourtierEnCommerce';
import { ProfessionnelNetworking } from './Components/pages/activites/ProfessionnelNetworking';
import { ApporteurDaffaires } from './Components/pages/activites/ApporteurDaffaires';
import { BusinessAngel } from './Components/pages/activites/BusinessAngel';
import { DeveloppeurDeCompetences } from './Components/pages/activites/DeveloppeurDeCompetences';
import { QuiSommesNous } from './Components/pages/rootsClub/QuiSommesNous';
import { MotDuPresident } from './Components/pages/rootsClub/MotDuPresident';
import { Adhesion } from './Components/pages/Adhesion';
import { Contact } from './Components/pages/Contact';
import { Marketplace } from './Components/pages/espaceMembre/Marketplace';
import { Networking } from './Components/pages/espaceMembre/Networking';
import { OportuniteAffaires } from './Components/pages/espaceMembre/OportuniteAffaires';
import { Academie } from './Components/pages/espaceMembre/Academie';
import { PrestataireService } from './Components/pages/espaceMembre/PrestataireService';
import { CentraleAchat } from './Components/pages/espaceMembre/CentraleAchat';
import { BoutiquePrivilege } from './Components/pages/espaceMembre/BoutiquePrivilege';
import { ArtEvenement } from './Components/pages/espaceMembre/ArtEvenement';
import { BureauExecutif } from './Components/pages/rootsClub/BureauExecutif';
import { EquipePermanente } from './Components/pages/rootsClub/EquipePermanente';
import { CharteDuClub } from './Components/pages/rootsClub/CharteDuClub';
import { LieuDeRencontre } from './Components/pages/avantagesDuClub/LieuDeRencontre';
import { ReseauDePrestataires } from './Components/pages/avantagesDuClub/ReseauDePrestataires';
import { CentraleDachat } from './Components/pages/avantagesDuClub/CentraleDachat';
import { LaBoutiquePrivilege } from './Components/pages/avantagesDuClub/LaBoutiquePrivilege';
import { ArtEtEvenement } from './Components/pages/avantagesDuClub/ArtEtEvenement';
import { Prestataires } from './Components/pages/rootsClub/Prestataires';
import { Partenaires } from './Components/pages/rootsClub/Partenaires';
import { MembresHonneur } from './Components/pages/rootsClub/MembresHonneur';
import { Evenements } from './Components/pages/Evenements';
import { EventDetails } from './Components/pages/Actualities/EventDetails';


function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Accueil />} />
        <Route path='/connexion' element={<SignIn />} />
        <Route path='/nos-activites/courtier-en-commerce-international' element={<CourtierEnCommerce />} />
        <Route path='/nos-activites/professionel-du-networking' element={<ProfessionnelNetworking />} />
        <Route path='/nos-activites/apporteur-daffaires' element={<ApporteurDaffaires />} />
        <Route path='/nos-activites/business-angel' element={<BusinessAngel />} />
        <Route path='/nos-activites/developpeur-de-competences' element={<DeveloppeurDeCompetences />} />
        <Route path='/qui-sommes-nous' element={<QuiSommesNous />} />
        <Route path='/mot-du-president' element={<MotDuPresident />} />
        <Route path='/charte-du-club' element={<CharteDuClub />} />
        <Route path='/bureau-executif' element={<BureauExecutif />} />
        <Route path='/equipe-permanente' element={<EquipePermanente />} />
        <Route path='/membres-dhonneur' element={<MembresHonneur />} />
        <Route path='/prestataires' element={<Prestataires />} />
        <Route path='/partenaires' element={<Partenaires />} />
        <Route path='/event-details' element={<EventDetails />} />


        {/*  ----------------------Avantages du club----------------- */}
        <Route path='/avantages-du-club/lieu-de-rencontre' element={<LieuDeRencontre />} />
        <Route path='/avantages-du-club/reseau-de-prestataires' element={<ReseauDePrestataires />} />
        <Route path='/avantages-du-club/centrale-d-achat' element={<CentraleDachat />} />
        <Route path='/avantages-du-club/boutique-privilège' element={<LaBoutiquePrivilege />} />
        <Route path='/avantages-du-club/arts-et-evenements' element={<ArtEtEvenement />} />


        <Route path='/adhesion' element={<Adhesion />} />
        <Route path='/contact' element={<Contact />} />
        {/*  ----------------------espaceMembre----------------- */}

        <Route path='/marketplace' element={<Marketplace />} />
        <Route path='/networking' element={<Networking />} />
        <Route path='/opportunite-d-affaire' element={<OportuniteAffaires />} />
        <Route path='/academie' element={<Academie />} />
        <Route path='/prestataires-services' element={<PrestataireService />} />
        <Route path='/centrale-d-achat' element={<CentraleAchat />} />
        <Route path='/boutique-privilege' element={<BoutiquePrivilege />} />
        <Route path='/arts-et-evenements' element={<ArtEvenement />} />
        <Route path='/evenements/:category' element={<Evenements />} />
        <Route path='/evenements/:category?' element={<Evenements />} />



      </Routes>
    </>
  );
}

export default App;
