import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import marketplace from '../../../Images/marketplace.jpg'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import OwlCarousel from 'react-owl-carousel';
import { ProduitsApprovisionnementRepository } from '../../../repositories/ProduitsApprovisionnementRepos'

export const CourtierEnCommerce = () => {

    const options = {
        items: 4,
        margin: 40,
        nav: false,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 6000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };

    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl font-semibold flex-none '>Commerce international</h1>
                <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
            </div>
            <div className='pt-10 px-10 mb-10 grid gap-y-4 grid-cols-1 md:grid-cols-2  '>
                <div className='md:w-[400px] md:h-[320px] order-last md:order-first  '>
                    <img src={marketplace} alt="Courtier commerce" />
                </div>
                <div>
                    <p>
                        Notre club sort ses adhérents de l’anonymat et les plonge dans un milieu de centaines d’entreprises et de marchés, avec des contacts au bout des doigts. Ils peuvent ainsi publier et populariser leurs produits et services au sein de la Marketplace du Club ou à l’inverse faire des achats.
                    </p>
                    <br />
                    <p className=''> Notre Marketplace Achat-Vente compte:
                        <ul className='mt-3'>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> 4 millions de références</li>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> +120 000 fournisseurs</li>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> +140 pays dans le monde</li>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> + 2 millions de visiteurs chaque mois</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className='px-6 md:mt-[-50px] mb-5'>
                <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                    <h2 className='border-l-[4px] border-black pl-4'> Produits d'approvisionnement</h2>
                </div>
                <div className="carousels px-5">
                    <OwlCarousel className='owl-theme py-5' loop {...options}>
                        {
                            ProduitsApprovisionnementRepository.map((produit, index) =>
                                <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                    <img className='border-b h-[80%] object-cover' src={produit.picture} alt={produit.name} />
                                    <h3 className='text-xl font-bold text-center text-black mt-2'>{produit.name}</h3>
                                </div>
                            )
                        }
                    </OwlCarousel>
                </div>
            </div>
            <Footer />

        </div>
    )
}
