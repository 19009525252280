import React from 'react'
import Header from '../Common/Header'
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Footer } from '../Common/Footer';
import { useRef, useContext } from 'react';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import logoSvg from '../../Images/logoroots2.png'




export const SignIn = () => {

    const navigate = useNavigate();

    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const [validation, setValidation] = useState("");

    const handleForm = async (e) => {
        e.preventDefault()

        // const email = emailRef.current.value;
        // const password = passwordRef.current.value;

        // axios
        // .post('http://localhost:8000/api/login', {
        //     email: email,
        //     password: password
        // })

        // .then(response => {
        //     setIsLoggedIn(true);
        //     console.log(isLoggedIn)

        // } )

        // .catch(error => {
        //     setValidation('Email ou Mot de passe Incorrect')
        // } )

    }

    // useEffect(() => {
    //     // check if the user is loggedIn
    //     if (isLoggedIn) {
    //       // redirect to the homepage
    //      navigate('/marketplace')
    //     }
    //   }, [isLoggedIn]);

    return (
        < >
            {/* <Header /> */}
            <div className="logo w-[40%] mt-6 mx-auto ">
                <NavLink to='/' >
                    <img className='w-100' src={logoSvg} alt="Le logo" />
                </NavLink>
            </div>
            <div className="title p-6 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl font-semibold '>Connexion</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 my-11 px-6 gapx-2 gap-y-4">
                <div className='md:w-[80%]  text-center order-last md:order-first'>
                    <h2 className='text-lg font-semibold underline text-red-800'>Vous n’êtes pas encore inscrit ?</h2>
                    <p className='my-6 font-semibold'>«  Inscrivez-vous dès  aujourd’hui…. »</p>
                    <button className='bg-red-800'>S’inscrire...</button>
                </div>
                <div className='md:w-[90%]'>
                    <h2 className='text-lg text-center font-semibold underline'> Mon Espace Membre </h2>
                    <form className="signIn-form mt-5 py-8 px-12 rounded-lg shadow-lg" onSubmit={handleForm}>
                        <div className="mb-3">
                            <label className='block mb-3' htmlFor="SignINEmail">Email</label>
                            <input
                                type="email" className='border border-black outline-none p-2 w-full rounded-lg bg-[#e4e4e7]' name="email" id="signUpEmail" ref={emailRef} required placeholder='Email' />
                        </div>
                        <div className="mb-6">
                            <label className='block mb-3' htmlFor="SignInPwd" >Mot de passe</label>
                            <input
                                type="password" className='border border-black outline-none  p-2 w-full rounded-lg bg-[#e4e4e7]' name="password" id="signUpPwd" ref={passwordRef} required placeholder='Mot de passe' />
                            <p className='text-danger mt-1'>{validation}</p>
                        </div>
                        <button>
                            <NavLink to='/marketplace' >
                                Se connecter
                            </NavLink>
                        </button>
                    </form>
                </div>
            </div>

            <Footer />
        </>
    )
}
