import React from 'react'
import business from '../../../Images/business.jpg'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import OwlCarousel from 'react-owl-carousel';
import op1 from '../../../Images/affaires/op1.jpeg'
import op2 from '../../../Images/affaires/ouvertureCapital.jpg'
import introductionbourse from '../../../Images/introductionbourse.jpg'
import banque from '../../../Images/assurance.jpg'


export const BusinessAngel = () => {

    const options = {
        items: 4,
        margin: 40,
        nav: false,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 6000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };
    return (
        <>
            <div className='bg-[#e4e4e7]'>
                <Header />
                <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                    <h1 className='text-lg md:text-2xl  font-semibold flex-none '>Business angel </h1>
                    <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
                </div>
                <div className='pt-10 px-10 mb-10 gap-y-4 grid grid-cols-1 md:grid-cols-2  '>
                    <div className='md:w-[400px] md:h-[350px] order-last md:order-first  '>
                        <img src={business} alt="Courtier commerce" />
                    </div>
                    <div>
                        <p>
                            Notre club d’affaires vous apporte des solutions pour  financer vos projets innovants à forte valeur ajoutée ayant un potentiel de croissance exponentielle.
                            Nous vous assistons et accompagnons de la rédaction de votre projet à la signature du pacte des actionnaires.
                        </p>
                        <br />
                        <p> Levez des fonds pour votre projet :
                            <ul className='mt-3'>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Investissement financier direct</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Solutions bancaires</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Ouverture de capital</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Introduction en bourse</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='px-6 md:mt-[-50px] mb-5'>
                    <div className='title mb-6 text-lg md:text-[28px] '>
                        <h2 className='border-l-[4px] border-black pl-4'> Nos solutions de financement</h2>
                    </div>
                    <div className="carousels px-5">
                        <OwlCarousel className='owl-theme py-5' loop {...options}>
                            <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='border-b h-[80%] object-cover' src={op1} alt="projets financés" />
                                <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Constructeur financier</h3>
                            </div>
                            <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='border-b h-[80%] object-cover' src={banque} alt="projets financés" />
                                <h3 className='text-xs md:text-sm text-center text-black mt-2'>Solutions bancaires</h3>
                            </div>
                            <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='border-b h-[80%] object-cover' src={op2} alt="projets financés" />
                                <h3 className='text-xs md:text-sm text-center text-black mt-2'>Ouverture de capital</h3>
                            </div>
                            <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='border-b h-[80%] object-cover' src={introductionbourse} alt="projets financés" />
                                <h3 className=' text-xs md:text-sm  text-center text-black mt-2'>Bourse de Casablanca: Financement des entreprises africaines par le marché des capitaux</h3>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>

                <Footer />

            </div>
        </>
    )
}
