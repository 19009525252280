import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import { PartenersRepository } from '../../../repositories/PartenersRepository'


export const Partenaires = () => {

    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Ambassades et nos Partenaires </h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className=" mt-[-10px] mb-12 py-6 px-6">
                <div className='grid grid-cols-1 md:grid-cols-5 gap-4 '>
                    {PartenersRepository.map((partener, index) => (
                        <div key={partener.name + index} className='md:w-[150px] h-[200px] md:h-[105px]  border shadow-lg border-gray-300 overflow-hidden'>
                            <img className=' w-full h-full ' src={partener.picture} alt={partener.name} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}
