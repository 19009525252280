import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import manal from '../../../Images/comiteHonneur/manal.PNG'
import user from '../../../Images/user.png'

export const MembresHonneur = () => {
    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Comité d'honneur</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className=" m-10 grid grid-cols-1 md:grid-cols-3  md:px-5">
                <div className='mb-6  flex items-center justify-center flex-col md:w-[300px]'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                        <img className='border-b' src={user} alt="membre d'honneur" />
                    </div>
                    <h3 className='text-lg font-bold text-center py-3 text-black uppercase '>SEM. Mouhamadou Youssifou</h3>
                    <span className='text-primary text-center'>Doyen des Ambassadeurs et Ambassadeur du Cameroun au Maroc</span>
                </div>
                <div className='mb-6 flex items-center justify-center flex-col md:w-[300px]'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                        <img className='border-b' src={user} alt="membre d'honneur" />
                    </div>
                    <h3 className='text-lg font-bold text-center py-3 text-black'>SEM. RAOUL ARAGAO</h3>
                    <span className='text-primary text-center'>Ambassadeur de la République Démocratique de Sao Tomé et Principe au Maroc</span>
                </div>
                <div className='mb-6  flex items-center justify-center flex-col md:w-[300px]'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                        <img className='border-b' src={manal} alt="membre d'honneur" />
                    </div>
                    <h3 className='text-lg font-bold text-center py-3 text-black'>Mme. MANAL CHERKI</h3>
                    <span className='text-primary text-center'>Membre du Conseil Internationnal de la Danse auprès de l'UNESCO et Artiste Pluridisciplinaire</span>
                </div>
            </div>
            <Footer />
        </div>
    )
}
