// import company from '../Images/company.png'
import assurance from '../Images/formations/assurance.jpg'
import auxilliaireVie from '../Images/formations/auxilliaireVie.jpeg'
import hotelerie from '../Images/formations/hotelerie.PNG'
import entreprenariat from '../Images/formations/entreprenariat.jpg'


export const FormationsRepository = [
    {
        name: 'Formation technico-commerciale en assurance ',
        picture: assurance,

    },
    {
        name: "Formation aux métier de l'hôtellerie  ",
        picture: hotelerie,

    },
    {
        name: "Formation aux métiers d'auxiliaire de vie ",
        picture: auxilliaireVie,

    },
    {
        name: "Formation à l'entreprenariat ",
        picture: entreprenariat,
        advantages: "et création d'AGR"

    },

]
