import React from 'react'
import { Header2 } from '../../Common/Header2'
import { Footer } from '../../Common/Footer'
import galien from '../../../Images/prestataires/galien.jpeg'
import { FaLocationArrow, FaUser, FaFacebook, FaInstagram } from 'react-icons/fa'
import PrestatairesFilter from '../../Common/PrestatairesFilter'

// import axios from 'axios';
import { useState, useEffect } from 'react'


export const PrestataireService = () => {

    const [prestataires, setPrestataires] = useState([]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get('/api/prestataires');
    //             setPrestataires(response.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }

    //     fetchData();
    // }, []);


    const handleFilterChange = async (filters) => {
        try {
            // const response = await axios.get('/api/providers', {
            //     params: filters
            // });
            // setPrestataires(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Header2 />
            <div className='px-10 my-10'>
                <h1 className=' my-7 text-lg md:text-2xl font-semibold border-l-[4px] border-black pl-4'>Consultez et choisissez votre prestataire de service</h1>
                <PrestatairesFilter onFilterChange={handleFilterChange} />
            </div>
            <div className="  px-6 my-10">
                <div className="grid grid-cols-1 md:grid-cols-3  ">
                    <div className='flex justify-center py-3 pr-6 pl-3 shadow-xl rounded-lg bg-white text-[13px] '>
                        <div className=''>
                            <img className=' w-[25%]' src={galien} alt="prestataire logo" />
                            <div>
                                <div className='flex space-x-1 mt-1'>
                                    <FaUser size={20} color='#e68106' />
                                    <p className='nom_prestataire font-bold '>ENNAJI HIND</p>
                                </div>
                                <p className='statut_prestatire font-bold'>Docteur en Pharmacie <br />  Homéopathe et Naturophate</p>
                                <div className='flex'>
                                    <FaLocationArrow size={18} color='#e68106' />
                                    <p className='my-1'>  Angle Bld Med Belhassan

                                    </p>

                                </div>
                                <p>
                                    El Ouazzani et Rue Sanhaja
                                    <br />
                                    Souissi, Rabat</p>
                                <p> <span className='font-bold'>Tél:</span> <span><a href="tel:+212537759747 ">(+212) 5377-59747</a></span></p>
                                <p className='my-1'> <span className='font-bold'>Fax:</span> <span><a href="tel:+212537630539 ">(+212) 5376-30539</a></span></p>
                                <p className='my-1' ><span className='font-bold'>GSM:</span> <span><a href="tel:+212641061859 ">(+212) 641-061859</a></span></p>

                                <div className='flex space-x-3'>
                                    <span> <a href=" https://www.instagram.com/invites/contact/?i=e2q85p3evgfe&utm_content=541f8g1" target="_blank" rel="noopener noreferrer"> <FaFacebook size={20} color='#e68106' /></a></span> <span>Pharmaciegalien</span>
                                </div>
                                <div className='flex space-x-3 my-2'>
                                    <span><FaInstagram size={20} color='#e68106' /></span> <span>Pharmacie.galien</span>
                                </div>
                            </div>
                        </div>
                        <div className='ml-[-70px]'>
                            <div className='font-bold informations_prestataires'>

                            </div>
                            <div className='services_prestataires'>
                                <p className='font-bold'>Services et Avantages octroyés aux membres du club:</p>
                                <ul className=' list-disc ml-3 mt-2 '>
                                    <li>
                                        Vente et Conseils médicaments et parapharmacie
                                    </li>
                                    <li>Préparations magistrales et officinales</li>
                                    <li>Consultations Homéopathiques</li>
                                    <li>Séances d'Auriculothérapie</li>
                                    <li>Perçage des oreilles </li>
                                </ul>
                            </div>
                            
                            <div className='avantages_membres'>
                               
                                <ul className=' list-disc ml-3 '>
                                    <li className='' > <strong>Réduction de -25% sur tous les produits parapharmaceutiques</strong> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
