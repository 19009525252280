import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import president from '../../../Images/president.png'

export const MotDuPresident = () => {
  return (
    <div className='bg-[#e4e4e7]'>
      <Header />
      <div className="title p-4 bg-[#a0a0a1] flex justify-between">
        <h1 className='text-lg md:text-2xl  font-semibold '>Mot du président</h1>
        <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
      </div>
      <div className="md:grid md:grid-cols-12 md:gap-3 md:mb-[50px] mb-[170px] text-center pt-10 pb-24 ">
        <div className='md:col-span-1'></div>
        <div className="md:col-span-7 padding_li text-justify px-4 md:px-0">
          <p className='my-3 text-[12px] md:text-[16px] ' >Mesdames et Messieurs,</p>
          <p className='my-3 text-[12px] md:text-[16px] ' >Business, Convivialité et Echange, sont les trois mots qui me sont spontanément venus à l’esprit pour qualifier Roots Club.</p>
          <p className='my-3 text-[12px] md:text-[16px] ' >
            Un club de dirigeants et d’entrepreneurs qui a pour vocation
            le dialogue et l’échange autour des pratiques du réseautage et
            du développement des affaires.
          </p>
          <p className='my-3 text-[12px] md:text-[16px] ' >
            J’ai l’honneur de diriger un club fort de sa communauté diplomatique engagée au service du développement
            durable du continent africain, légitime par ses activités de :
          </p>
          <p className='my-3 text-[12px] md:text-[16px] ' >
            <ul className='list-disc ml-6'>
              <li>Networking,</li>
              <li>Commerce international,</li>
              <li>Marchés clés en main, </li>
              <li>Solutions de financement de projets publics et privés, </li>
              <li>Evénements professionnels et culturels,</li>
              <li>Ateliers de coaching et de formation labélisés. </li>
            </ul>
          </p>
          <p className='my-3 text-[12px] md:text-[16px] ' >
            Et ceci dans le but d’accompagner nos membres dans leur développement professionnel tout au long de leur carrière.
          </p>
          <p className='my-3 text-[12px] md:text-[16px] ' >
            Notre ambition, la mienne et celle de mon équipe est d’asseoir le leadership rassembleur et fédérateur de Roots Club dans un espace d’échange et de mise en relation d’affaires, dédié au service de la promotion
            et de la croissance durable de chacun des pays du continent africain.
          </p>
          <p className='my-3 text-[12px] md:text-[16px] ' >
            J’aimerais remercier tous les membres du conseil d’administration qui consacrent leur temps à titre personnel aux travaux et aux activités de notre club d’affaires. Leur engagement exige une participation constante et responsable et qui permet à Roots Club de se développer. L’union de nos expertises fait la force !
          </p>
          <p className='my-3 text-[12px] md:text-[16px]'>J’aimerais également souhaiter chaleureusement la bienvenue aux nouveaux membres et partenaires, nous sommes heureux de pouvoir partager nos compétences et expertises communes.</p>
          <p className='my-3 text-[12px] md:text-[16px] ' >Merci de votre confiance.</p>
        </div>
        <div className='md:col-span-1'></div>
        <div className='md:col-span-3 md:h-[200px]  '>
          <img src={president} alt="Le president" />
          <div className=' pr-3 uppercase text-right font-bold border-r-4 border-primary'>
            <h2 >Reda Ennaji</h2>
            <h2 className='font-semibold normal-case' > <i>Président de Roots Club</i> </h2>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  )
}
