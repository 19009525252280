import React from 'react'





export const Prestataires = () => {

  return (

    <div className='bg-[#e4e4e7]'>
      {/* <Header />
      <div className="title p-4 bg-[#a0a0a1] flex justify-between">
        <h1 className='text-2xl  font-semibold '>Nos Prestataires</h1>
        <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-2 px-6 my-10 ">
        <div className="grid grid-cols-1 md:grid-cols-2 shadow-xl rounded-lg bg-white text-sm">
          <div className='flex items-center justify-center flex-col'>
            <div className=' p-3 '>
              <img className=' ' src={galien} alt="prestataire logo" />
              <p className='font-bold' >Pharmacie et Parapharmacie GALIEN spécialisée en naturopathie et en homéopathie</p>
              <br />
              <p className='font-bold'>Adresse</p>
              <p> Angle Boulevard Mohamed BelHassan
                <br />
                Rue Sanhaja, Souissi, Rabat
                <br />
              </p>
              <br />
              <p> <span className='font-bold'>Tél:</span> <span><a href="tel:+212537759747 ">(+212) 5377-59747</a></span></p>
              <p className='my-1'> <span className='font-bold'>Fax:</span> <span><a href="tel:+212537630539 ">(+212) 5376-30539</a></span></p>
              <p ><span className='font-bold'>GSM:</span> <span><a href="tel:+212641061859 ">(+212) 641-061859</a></span></p>
              <br />
              <div className='flex space-x-3'>
                <span> <a href=" https://www.instagram.com/invites/contact/?i=e2q85p3evgfe&utm_content=541f8g1" target="_blank" rel="noopener noreferrer"> <FaFacebook size={20} color='#e68106' /></a></span> <span>Pharmaciegalien</span>
              </div>
              <div className='flex space-x-3 my-3'>
                <span><FaInstagram size={20} color='#e68106' /></span> <span>Pharmacie.galien</span>
              </div>
            </div>
          </div>
          <div className='flex flex-col md:ml-6 p-3 space-y-2'>
            <div className='font-bold informations_prestataires'>
              <p className='nom_prestataire '>ENNAJI HIND</p>
              <p className='statut_prestatire'>Docteur en Pharmacie Homéopathe et Naturophate</p>
            </div>
            <div className='services_prestataires'>
              <p className='font-bold'>Services :</p>
              <ul className=' list-disc ml-3 '>
                <li>
                  Vente et Conseils médicaments
                </li>
                <li>Vente et Conseils parapharmacie</li>
                <li>Préparations magistrales et officinales</li>
                <li>Consultations Homéopathiques</li>
                <li>Séances d'auriculothéraphie</li>
                <li>Perçage des oreilles </li>
              </ul>
            </div>
            <br />
            <div className='avantages_membres'>
              <p className='font-bold'>Avantages octroyés aux membres du club:</p>
              <ul className=' list-disc ml-3 '>
                <li>
                  Livraison à domicile à rabat pour toute commande supérieure à 500 DHS
                </li>
                <li>Réduction de -25% sur tous les produits parapharmaceutiques</li>
              </ul>
            </div>
          </div>

        </div>


      </div>
      <Footer /> */}
    </div>
  )
}

