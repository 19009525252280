import React from 'react'
import { Header2 } from '../../Common/Header2'
import market from '../../../Images/market.jpg'
import { NavLink } from 'react-router-dom'
import { Footer } from '../../Common/Footer'

export const Marketplace = () => {
  return (
    <div>
      <Header2 />
      <div className="banieremartket w-full md:h-[70vh] relative">
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full backdrop-brightness-50 bg-black/10'></div>
        <img src={market} className='h-full w-full object-cover' alt="marketplace" />
        <div className='absolute top-[40%] left-[10%]  z-10 text-white text-center '>
          <h1 className='md:text-[40px] font-bold mb-4'>
            Votre plateforme d'achat-vente internationale
          </h1>
          <span className='bold tracking-wide mr-6 md:text-3xl font-bold '>+120 000 fournisseurs</span><span className='bold tracking-wide mr-6 md:text-3xl font-bold '>+140 pays</span><p className='bold tracking-wide mr-6 md:text-3xl font-bold '>+4 millions de réferences</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 my-11 px-6 gap-2">
        <div className='md:w-[80%]  text-center order-last md:order-first'>
          <h2 className='text-lg font-semibold underline text-red-800'>Vous n’êtes pas encore inscrit ?</h2>
          <p className='my-6 font-semibold'>«  Inscrivez-vous dès  aujourd’hui…. »</p>
          <button className='bg-red-800'>S’inscrire...</button>
        </div>
        <div className='md:w-[90%]'>
          <h2 className='text-lg font-semibold underline text-center'>Mon Espace Marketplace</h2>
          <form className="signIn-form mt-5 p-6 rounded-lg shadow-lg">
            <div className="mb-3">
              <label className='block mb-3' htmlFor="SignINEmail">Email</label>
              <input
                type="email" className='border border-black p-2 w-full rounded-lg bg-[#e4e4e7]' name="email" id="signUpEmail" required placeholder='Email' />
            </div>
            <div className="mb-6">
              <label className='block mb-3' htmlFor="SignInPwd" >Mot de passe</label>
              <input
                type="password" className='border border-black p-2 w-full rounded-lg bg-[#e4e4e7]' name="password" id="signUpPwd" required placeholder='Mot de passe' />
            </div>
            <button>
              <NavLink to='/' >
                Se connecter
              </NavLink>
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>

  )
}
