import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'

export const CharteDuClub = () => {
    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Charte du club</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className="md:grid md:grid-cols-12  text-center py-10">
                <div className='md:col-span-2'></div>
                <div className="md:col-span-8 padding_li text-[12px] md:text-[16px] px-4 md:px-0">
                    <h3 className='text-sm md:text-xl mx-6 md:mx-32leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>La charte de Roots club</h3>
                    <p className='my-6 text-justify'>
                        La philosophie des fondateurs de <b>ROOTS CLUB</b>  est née de la volonté d'une poignée d’hommes et de femmes qui souhaitent défendre l'idée d'entreprenariat et de réseau fondée sur trois valeurs essentielles : <b>Le Business, la Convivialité et l'Echange</b>.
                    </p>

                    <p className='my-6 text-justify'>
                        La mission de ROOTS CLUB est d’assurer un lieu d&#39;échange et de partage
                        d'expériences issues de tous horizons, favorables au développement de
                        synergie et de signature de contrats.
                    </p>
                    <p className='my-6 text-justify text-red-800 font-bold' >LE MEMBRE S'ENGAGE À :    </p>
                    <p className='my-6 text-justify'>
                        <ul className='list-disc ml-6'>
                            <li>S'interdire tout prosélytisme politique, religieux ou syndical,</li>
                            <li>Respecter la confidentialité des échanges et des informations communiquées,</li>
                            <li>
                                Pratiquer un mode d'échange équitable et généreux avec les
                                membres et bienveillant envers les invités et les intervenants,
                            </li>
                            <li>Promouvoir le club et porter ses valeurs dans son univers professionnel,</li>
                            <li>
                                Participer à la vie de ROOTS CLUB par sa présence et sa forte
                                implication,
                            </li>
                            <li>
                                Contribuer au développement de ROOTS CLUB en assurant son
                                rayonnement et en favorisant le parrainage,
                            </li>
                            <li>
                                Apporter dans le cadre de la vie de ROOTS CLUB l&#39;expertise de son
                                domaine de compétence, </li>
                            <li>Payer sa cotisation chaque année et à bonne échéance. La dite
                                adhésion est renouvelable par tacite reconduction, sauf dénonciation
                                par l’une des parties avec un préavis de trois mois.</li>
                        </ul>
                    </p>
                    <div>
                        <p className='my-6 text-justify text-red-800 font-bold' >ROOTS CLUB S'ENGAGE À :   </p>
                        <p className='my-6 text-justify'>
                            <ul className='list-disc ml-6'>
                                <li>Etre neutre et indépendant de toute organisation politique,
                                    religieuse, ou syndicale, tout en s'interdisant toute prise de position
                                    formelle,</li>
                                <li>Garantir la libre expression des opinions de ses adhérents dans un
                                    cadre pluriel et dans un espace d’engagement citoyen,</li>
                                <li>Assurer la liberté de parole et de pensée à l'intérieur du club, dans
                                    le respect des opinions d'autrui,</li>
                                <li>Respecter la confidentialité des informations communiquées par les
                                    membres (les renseignements des fiches signalétiques des membres
                                    seront strictement confidentiels et ne seront destinés qu'aux usages
                                    internes du Club), </li>
                                <li> Favoriser les contacts et les rencontres entre les membres dans des
                                    environnements propices ou par des canaux facilitant la qualité et la
                                    fluidité des échanges,</li>
                                <li> Fournir aux membres une information pertinente et enrichissante
                                    leur permettant d'élargir la vision de leur environnement
                                    professionnel et personnel.</li>
                            </ul>
                        </p>
                    </div>
                    <div className='padding_li'>
                        <p className='my-6 text-justify text-red-800 font-bold uppercase' >Qualité des membres du ROOTS CLUB :  </p>
                        <p className='my-6 text-justify'>
                            <ul className='list-disc ml-6'>
                                <li> <span className='font-bold underline'>Membre d’Honneur :</span> Notre Club d’Affaires réserve à certaines
                                    personnes la qualité de membre d&#39;honneur. Cette attribution
                                    reconnait les services et contributions exceptionnels rendus par un
                                    individu aussi bien au club qu’à la société civile. Le membre
                                    d’honneur est élu pour une année et le titre peut être éventuellement
                                    renouvelé. </li>
                                <li> <span className='font-bold underline'>Membre Partenaire :</span> Notre Club d’Affaires développe des
                                    partenariats avec des entreprises, des associations et/ou des
                                    organismes avec lesquels nous pouvons échanger stratégiquement
                                    et trouver une entente commune afin d’apporter aux autres
                                    membres de notre club, de nouveaux produits et services et de
                                    nouvelles opportunités de Business.

                                </li>
                                <li><span className='font-bold underline'>Membre Adhérent :</span> Des entrepreneurs au sens large du terme,
                                    femmes et hommes créateurs, repreneurs ou dirigeants
                                    d&#39;entreprises de tous secteurs d&#39;activité qui partagent des valeurs
                                    communes, à savoir le réseau et le développement des affaires. Ils
                                    participent activement aux réunions mensuelles organisées par les
                                    animateurs et contribuent, par leurs expertises et leur savoir-faire, à
                                    la vie du groupe et au développement du chiffre d’affaires. </li>

                                <li><span className='font-bold underline'>Membre Animateur :</span> Proche de nos membres adhérents, de leurs
                                    attentes et de leurs besoins, nos animateurs sont chargés
                                    d’organiser des rencontres, de créer des événements et de faire
                                    intervenir des experts de renommés dans les domaines utiles.
                                    Restant toujours à l’écoute de nos adhérents, ils doivent préserver
                                    un esprit d’équipe autour du concept de ROOTS CLUB, à savoir
                                    <strong> Partage, Convivialité, Entraide et Valeurs Humaines</strong> . </li>

                                <li><span className='font-bold underline'>Membre Intervenant :</span> Consultant, coach ou expert, nos
                                    intervenants travaillent en concert avec nos membres animateurs,
                                    et sont sélectionnés pour leurs qualités humaines et leur fort capital
                                    expérience dans leur domaine de compétence.</li>

                                <li><span className='font-bold underline'>Membre Délégué :</span> Représentant territorial, le délégué dispose
                                    d’une lettre de mission spécifique pour promouvoir  l’image et  la notoriété de
                                    ROOTS CLUB et étendre aussi bien notre réseau de partenariats que
                                    celui de notre réseau d’adhérents.</li>

                                <li><span className='font-bold underline'>Membre Prestataire :</span> Ayant un statut de commerçant, le prestataire
                                    propose des produits et services à des prix compétitifs à notre
                                    communauté d’adhérents. </li>

                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
