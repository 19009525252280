import React from 'react'
import networking2 from '../../../Images/networking2.png'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import { FaArrowAltCircleRight } from 'react-icons/fa'
// import OwlCarousel from 'react-owl-carousel';
import netw1 from '../../../Images/networking/netw1.jpg'
import netw2 from '../../../Images/networking/netw2.jpg'

export const ProfessionnelNetworking = () => {

    // const options = {
    //     items: 3,
    //     margin: 40,
    //     nav: true,
    //     rewind: true,
    //     autoplay: false,
    //     smartSpeed: 2000,
    //     slideBy: 1,
    //     autoplayTimeout: 6000,
    //     responsive: {
    //         0: {
    //             items: 1,
    //         },
    //         600: {
    //             items: 2,
    //         },
    //         1000: {
    //             items: 3,
    //         },
    //     }
    // };
    return (
        <>
            <div className='bg-[#e4e4e7]'>
                <Header />
                <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                    <h1 className='text-lg md:text-2xl  font-semibold flex-none '>Professionnel du networking</h1>
                    <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
                </div>
                <div className='pt-10  px-10 grid gap-y-3 grid-cols-1 md:grid-cols-2 '>
                    <div className='order-last md:order-first md:w-[400px] md:h-[320px]  '>
                        <img src={networking2} alt="Courtier commerce" />
                    </div>
                    <div>
                        <p>
                            Notre club organise des rencontres de networking. Il s’agit d’événements informels et conviviaux en petits ou grands comités. C’est l’occasion idéale pour faire le plein de contacts professionnels tout en relâchant la pression de la journée.
                        </p>
                        <br />
                        <p> Participez à nos évènements :
                            <ul className='mt-3'>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Speed Business Meeting</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Speed Job Meeting</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Speed Dating Meeting</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='px-6 md:mt-[-50px] mb-5'>
                    <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                        <h2 className='border-l-[4px] border-black pl-4'> Networking du Mois de Février 2023</h2>
                    </div>
                    <div className="carousels px-5">
                        {/* <OwlCarousel className='owl-theme py-5' loop {...options} nav> */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-4 ">
                            <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='border-b h-[80%] w-full object-cover' src={netw1} alt="Networking" />
                                <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Networking personnalisé avec SEM Raoul ARAGAO</h3>
                            </div>
                            <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='border-b  h-[80%] w-full object-cover object-top' src={netw2} alt="Networking" />
                                <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Networking personnalisé avec Mme Delalune KOBO Conseillère économique de l'ambassade Centraficaine au Maroc</h3>
                            </div>
                        </div>
                        {/* </OwlCarousel> */}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
