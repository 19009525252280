import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import OwlCarousel from 'react-owl-carousel';
import { ProduitsCentralesAchat } from '../../../repositories/ProduitsApprovisionnementRepos';

import fournituresbureau from '../../../Images/affaires/fournitures-bureau.PNG'
import hitech from '../../../Images/affaires/hitech2.jpg'
import auto from '../../../Images/auto.jpg'
import ameublemt from '../../../Images/affaires/ameublement.PNG'


export const CentraleDachat = () => {

    const options = {
        items: 4,
        margin: 40,
        nav: false,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };

    return (
        <div>
            <Header />
            <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Centrale d'achats </h1>
                <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
            </div>
            <div className='pt-10 px-10 mb-10 grid gap-y-4 grid-cols-1 md:grid-cols-2'>
                <div className='md:w-[400px] md:h-[350px] order-last md:order-first  '>
                    <div className="grid grid-cols-2 grid-rows-2 gap-3">
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={fournituresbureau} alt="Produits central d'achat" />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={ameublemt} alt="Produits central d'achat" />
                        <img className='h-[130px] w-full cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={hitech} alt="Produits central d'achat" />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={auto} alt="Produits central d'achat" />
                    </div>
                </div>
                <div>
                    <p>
                        Notre club joue un rôle d’intermédiaire entre les fournisseurs, les producteurs, les importateurs et nos adhérents. Nous proposons ainsi l’étude comparative des produits, la recherche des fournisseurs et la négociation des achats.
                    </p>
                    <br />
                    <p> Notre centrale d’achat permet de réaliser des économies substantielles, d’optimiser la gestion des stocks, de garantir la régularité des approvisionnements avec des livraisons très rapides.</p>
                </div>
            </div>
            <div className='px-6 md:mt-[-50px] mb-5'>
                <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                    <h2 className='border-l-[4px] border-black pl-4'> Une sélection de nos produits </h2>
                </div>
                <div className="carousels px-5">
                    <OwlCarousel className='owl-theme py-5' loop {...options} >
                        {
                            ProduitsCentralesAchat.map((produit, index) =>
                                <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                    <img className='border-b h-[80%] object-cover' src={produit.picture} alt={produit.name} />
                                    <h3 className=' text-sm md:text-lg font-bold text-center text-black mt-2'>{produit.name}</h3>
                                </div>
                            )
                        }

                    </OwlCarousel>
                </div>
            </div>
            <Footer />
        </div>

    )
}
