import React from 'react'
import { Header2 } from '../../Common/Header2'
import { NavLink } from 'react-router-dom'
import { Footer } from '../../Common/Footer'

export const CentraleAchat = () => {
    return (
        <div>
            <Header2 />
            <h1 className='md:mx-12 mx-6 my-7 text-lg md:text-2xl font-semibold border-l-[4px] border-black pl-4'>Formulez votre demande de devis personnalisé </h1>
            <div className='w-[90%] m-auto mb-10 '>
                <form className="signIn-form mt-5 p-6 rounded-lg shadow-lg">
                    <div className="mb-6">
                        <label className='block mb-3' htmlFor="name">Adhérent</label>
                        <input
                            type="text" className='border p-2 outline-none bg-transparent border-black w-full rounded-lg' name="name" id="name" required />
                    </div>
                    <div className="mb-6">
                        <label className='block mb-3' htmlFor="SignINEmail">Choisissez un produit</label>
                        <select name="product" id="product" className='border p-2 outline-none bg-transparent border-black w-full rounded-lg'>
                            <option value="volvo">Fournitures de bureau</option>
                            <option value="saab">Mobilier de bureau</option>
                            <option value="opel">Matériel Hitech</option>
                            <option value="audi">Ameublement</option>
                            <option value="audi">Automobile multi-marque</option>
                        </select>
                    </div>
                    <div className="mb-6">
                        <label className='block mb-3' htmlFor="sujet" >Autre produit</label>
                        <input
                            type="text" className='border p-2 outline-none bg-transparent border-black w-full rounded-lg' name="otherProduct" id="otherProduct" />
                    </div>
                    <div className="mb-6">
                        <label className='block mb-3' htmlFor="sujet" >Votre besoin</label>
                        <textarea className='border p-2 w-full rounded-lg outline-none bg-transparent border-black' name="message" id="message" cols="30" rows="10" required></textarea>
                    </div>
                    <button>
                        <NavLink to='/' >
                            Envoyer
                        </NavLink>
                    </button>
                </form>
            </div>
            <Footer />
        </div>
    )
}
