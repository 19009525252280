import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import OwlCarousel from 'react-owl-carousel';
import event1 from '../../../Images/events/event.PNG'
import netw1 from '../../../Images/networking/netw1.jpg'
import netw2 from '../../../Images/networking/netw2.jpg'
import ateliers from '../../../Images/ateliers.png'
import expo from '../../../Images/expo.png'
import kasbaFete from '../../../Images/lieuR/kasbaFete.PNG'
import festivals from '../../../Images/festivals.png'

export const ArtEtEvenement = () => {
    const options = {
        items: 4,
        margin: 40,
        nav: true,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };
    return (
        <div>
            <Header />
            <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Evénementiel </h1>
                <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
            </div>
            <div className='pt-10 px-10 mb-10 grid gap-y-4 grid-cols-1 md:grid-cols-2'>
                <div className='md:w-[400px] md:h-[320px] order-last md:order-first  '>
                    <div className="grid grid-cols-2 grid-rows-2 gap-3">
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={ateliers} alt="ateliers" />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={expo} alt="Exposition arts" />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={kasbaFete} alt="Dinners Soiree " />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={festivals} alt="Festivals" />
                    </div>
                </div>
                <div>
                    <p>Notre club œuvre pour la promotion de l’art sous toutes ses formes : Architecture, sculpture, peinture et dessin, musique, littérature et poésie, cinéma et arts médiatiques, arts scèniques et bande dessinée.
                    </p>
                    <br />
                    <p>Nos adhérents pourront compter sur nos équipes de professionnels pour leur proposer un artiste ou leur organiser un événement réussi.
                    </p>
                </div>
            </div>
            <div className='px-6 md:mt-[-50px] mb-5'>
                <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                    <h2 className='border-l-[4px] border-black pl-4'> Un aperçu de nos évènements </h2>
                </div>
                <div className="carousels px-5">
                    <OwlCarousel className='owl-theme py-5' loop {...options} nav>
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className='border-b h-[80%] w-full ' src={event1} alt="Aperçu de l'évènement" />
                            <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Journée de lancement de ROOTS CLUB <br /> Mercredi 08 Février 2023 - 10H30</h3>
                        </div>
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className='border-b h-[80%] w-full object-cover' src={netw1} alt="Networking" />
                            <h3 className='text-xs md:text-sm   text-center text-black mt-2'>Networking personnalisé avec SEM Raoul ARAGAO</h3>
                        </div>
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className='border-b  h-[80%] w-full object-cover object-top' src={netw2} alt="Networking" />
                            <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Networking personnalisé avec Mme Delalune KOBO Conseillère économique de l'ambassade Centrafricaine au Maroc</h3>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            <Footer />
        </div>
    )
}
