import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'

export const QuiSommesNous = () => {
    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Qui sommes nous?</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className="md:grid md:grid-cols-12  text-center py-10">
                <div className='md:col-span-2'></div>
                <div className="md:col-span-8 text-[12px] md:text-[16px] px-4 md:px-0">
                    <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Le Concept de Roots club</h3>
                    <p className='my-6 text-justify'><b>ROOTS CLUB</b>  est le résultat de l’imagination, l’observation et l’expérience de plusieurs professionnels de divers secteurs d’activité. Tous ont constaté le manque d’un espace et d’une structure pour faciliter les rencontres et le développement des relations d’affaires. C’est pourquoi, ils ont décidé de mettre en commun leurs moyens intellectuels et logistiques, leurs carnets d'adresses de contacts et d'entreprises au Maroc et à l'étranger...
                    </p>
                    <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Principes et
                        Objectifs
                    </h3>
                    <p className='my-6 text-justify'>
                        <strong>ROOTS CLUB</strong>, véritable réseau d'entrepreneurs et de dirigeants d'entreprise, permet à nos adhérents de :
                        <ul className='list-disc ml-6 mt-2'>
                            <li><b>Trouver des Clients. </b>
                                Rejoindre notre club d’affaires c’est avant tout une opportunité de se mettre en avant. Un moyen de proposer ses produits et ses services, d’être recommandé et d'accroître son chiffre d’affaires,
                            </li>
                            <li><b>Pérenniser son activité. </b>
                                Au delà des recommandations, le réseau de notre club d’affaires permet de favoriser le développement des affaires, d’aiguiser ses compétences, de partager ses expériences et d’assurer son activité sur le long terme,
                            </li>
                            <li><b>Appartenir à une communauté solidaire. </b>
                                En cas de difficultés ou d’interrogations, notre club d’affaires et sa communauté sont à votre écoute et disposition. Un réseau d’entrepreneurs solidaires, c’est aussi l’assurance de trouver des solutions rapidement et facilement,
                            </li>
                            <li><b>Développer des Partenariats. </b>
                                Entreprendre est une aventure. Et chaque relation supplémentaire de votre réseau est une opportunité de développer une nouvelle activité, ou d'agrandir celle existante.
                            </li>
                        </ul>
                    </p>
                    <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Engagement
                    </h3>
                    <p className='mt-6 mb-2 text-justify'> <strong>ROOTS CLUB</strong> se positionne en véritable <b>Apporteur d’Affaires </b>
                        et propose à nos adhérents des offres de marché clé en main, aussi bien sur le territoire national qu’à l’étranger.
                    </p>
                    <p className='mb-6 text-justify'>
                        Il apporte également durant ce processus un accompagnement juridique, fiscal, comptable et en génie civil.
                    </p>
                    <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Valeurs et partages
                    </h3>
                    <p className=' mt-6 mb-2 text-justify'>
                        <strong>ROOTS CLUB</strong> mène également des actions sociales et artistiques, et favorise le mécénat et le développement durable.
                    </p>
                    <p className='text-justify'>
                        A titre d’exemple, notre club d’affaires finance directement ou indirectement les :
                        <ul className='list-disc ml-6 mt-2'>
                            <li>Associations humanitaires ou organismes marocains et sub africains d’intérêt général,
                            </li>
                            <li>Programmes de développement des zones marocaines et sub africaines en difficulté,
                            </li>
                            <li>Campagnes de vaccination et de distribution de médicaments,
                            </li>
                            <li>
                                Campagnes de promotion et de développement des artistes marocains et sub africains.
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
