import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import OwlCarousel from 'react-owl-carousel';
import lieuR1 from '../../../Images/lieuR/lieuR111.mp4'
import lieuR2 from '../../../Images/lieuR/kasbaFete.PNG'
import lieuR4 from '../../../Images/lieuR/bureauReunion.jpg'
import reunions from '../../../Images/lieuR/reunions.PNG'
import networking from '../../../Images/lieuR/networking.PNG'
import business from '../../../Images/lieuR/business.PNG'


export const LieuDeRencontre = () => {

    const options = {
        items: 4,
        margin: 40,
        nav: false,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };
    return (
        <div>
            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Lieu d'échange </h1>
                <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
            </div>
            <div className='pt-10 px-12 mb-10 grid gap-y-4 grid-cols-1 md:grid-cols-2  '>
                <div className='md:w-[400px] md:h-[320px] order-last md:order-first overflow-hidden  '>
                    <div className='w-full  '>
                        <video src={lieuR1} className='object-none w-full h-full ' muted autoPlay='true' controls="controls" />
                    </div>
                </div>
                <div>
                    <p>
                        Notre club d’affaires signe des conventions avec plusieurs établissements hôteliers ou maisons d’hôtes de la capitale du royaume pour offrir à ses adhérents des lieux d'échanges sécurisés, discrets et propices à la conjugaison du développement des opportunités d’affaires et de partenariats.
                    </p>
                </div>
            </div>

            <div className='px-6 md:mt-[-50px] mb-5'>
                <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                    <h2 className='border-l-[4px] border-black pl-4'> Nos différents sites d'échange </h2>
                </div>
                <div className="carousels px-5">
                    <OwlCarousel className='owl-theme py-5' loop {...options} >
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className=' h-[80%] object-cover' src={lieuR4} alt="Salle de réunion" />
                            <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Espace formations</h3>
                        </div>
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className=' h-[80%] object-cover' src={reunions} alt="Salle de réunion" />
                            <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Espace réunions</h3>
                        </div>
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className=' h-[80%] object-cover' src={networking} alt="Salle de réunion" />
                            <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Espace networking</h3>
                        </div>
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className=' h-[80%] object-cover' src={business} alt="Salle de réunion" />
                            <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Espace closing business</h3>
                        </div>
                        <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                            <img className=' h-[80%] object-cover' src={lieuR2} alt="Salle des fêtes" />
                            <h3 className='text-xs md:text-sm  text-center text-black mt-2'>Espace banquet</h3>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            <Footer />
        </div>
    )
}
