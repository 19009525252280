import React from 'react'
import { useState, useEffect } from 'react';


const moroccanCities = [
    'Casablanca',
    'Rabat',
    'Kenitra',
    'Marrakech',
    'Fes',
    'Tanger',
    'Meknes',
    'Oujda',
    'Agadir',
    'Tetouan',
    'Essaouira',
    'Laayoune'
    // ... add more Moroccan cities as needed
];


const sectors = [
    'Santé et Bien être',
    'BTP',
    'Génie Civil',
    'Import-Export',
    'Agriculture',
    'Pêche Maritime',
    'Industrie',
    'Logistique',

]



export default function PrestatairesFilter({ onFilterChange }) {

    const [selectedCity, setSelectedCity] = useState('');

    const [selectedSector, setSelectedSector] = useState('');

    const handleSectorChange = (event) => {
        setSelectedSector(event.target.value);
    }

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    }

    useEffect(() => {
        onFilterChange({ city: selectedCity, sector: selectedSector });
    }, [selectedCity, selectedSector]);



    return (
        <form className="signIn-form mt-5 p-6 rounded-lg shadow-lg">
            <div className="mb-3">
                <label className='block mb-3' htmlFor="city-select">Votre ville:</label>
                <select id="city-select" className='border p-2 w-full outline-none rounded-lg' value={selectedCity} onChange={handleCityChange}>
                    <option value=""></option>
                    {moroccanCities.map((city) => (
                        <option key={city} value={city}>{city}</option>
                    ))}
                </select>
            </div>
            <div className="mb-6">
                <label className='block mb-3' htmlFor="sector-select" >Secteur d'activité</label>
                <select id="sector-select" className='border p-2 w-full outline-none rounded-lg' value={selectedSector} onChange={handleSectorChange}>
                    <option value=""></option>
                    {sectors.map((sector) => (
                        <option key={sector} value={sector}>{sector}</option>
                    ))}
                </select>
            </div>
            <button>
                {/* <NavLink to='/' >
                            Consulter
                        </NavLink> */}
                Consulter
            </button>
        </form>
    )
}
