import React from 'react'
import { Banieres } from '../Common/Banieres'
import { Activities } from '../Common/Activities'
import { ClubAdvantages } from '../Common/ClubAdvantages'
import { ComingEvents } from '../Common/ComingEvents'
import { SecteursIntervention } from '../Common/SecteursIntervention'
import { LastActualities } from '../Common/LastActualities'
import { Footer } from '../Common/Footer'
import AccueilHeader from '../Common/AccueilHeader'


export const Accueil = () => {
    return (
        <>
            <AccueilHeader />
            <Banieres />
            <Activities />
            <ClubAdvantages />
            <ComingEvents />
            <SecteursIntervention />
            <LastActualities />
            <Footer />
        </>
    )
}
