import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import accueil from '../../../Images/events/accueil.jpg'
import user from '../../../Images/user.png'
import { FaClock, FaPause, FaUtensils, FaHourglassEnd } from 'react-icons/fa';
import president from '../../../Images/president.png'
import sigma from '../../../Images/events/sigma.png'
import eprogram from '../../../Images/partenaires/eprogram.PNG'
import yes from '../../../Images/yes.jpg'

export const EventDetails = () => {
    return (
        <>
            <Header />
            <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold flex-none '>Programme de l'évènement</h1>
                <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
            </div>
            <div className='border rounded-lg shadow-lg md:w-[90%] mx-auto my-10 p-6 ' >
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center '>
                    <div className='img-container w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <img className='w-full h-full object-cover' src={accueil} alt="Intervenant" />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >10 : 30</span></div>
                        <p className='py-2 text-lg text-primary font-bold ' >Accueil</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <img className='w-full h-full object-cover' src={user} alt="Intervenant" />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center  ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >11 : 30</span></div>
                        <p className='py-2 text-lg text-primary font-bold ' >Ouverture de la Conférence</p>
                        <hr />
                        <p className='py-2' >Mot du Doyen des Ambassadeurs</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <img className='w-full h-full object-cover' src={president} alt="Intervenant" />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >11 : 45</span></div>
                        <p className='py-2 text-sm  md:text-xl text-primary font-bold ' >Présentation du ROOTS CLUB</p>
                        <hr />
                        <p className='py-2' >Mot du Président du Club d’Affaires</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <img className='w-full h-full object-cover' src={sigma} alt="Intervenant" />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center  ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >12 : 00</span></div>
                        <p className='py-2 text-sm  md:text-xl text-primary font-bold ' >Couverture assurancielle des ambassades</p>
                        <hr />
                        <p className='py-2' >SIGMA ASSURANCE et Compagnie Sponsor Officiel</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <img className='w-full h-full object-cover' src={eprogram} alt="Intervenant" />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center  ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >12 : 30</span></div>
                        <p className='py-2 text-sm  md:text-xl text-primary font-bold ' >Avantages sociaux pour les diplomates</p>
                        <hr />
                        <p className='py-2' >NOVDISTY et E-program</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container flex justify-center items-center w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <FaPause size={30} color='black' />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center justify-center ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >13 : 00</span></div>
                        <p className='py-2 text-sm  md:text-xl text-primary font-bold ' >Pause</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container flex justify-center items-center w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <FaUtensils size={30} color='black' />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >13 : 30</span></div>
                        <p className='py-2 text-sm  md:text-xl text-primary font-bold ' >Déjeuner/Buffet sur place</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <img className='w-full h-full object-cover' src={yes} alt="Intervenant" />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center justify-center ' ><FaClock size={20} color='black' /> <span className='text-gray-400 text-sm  md:text-xl font-bold ' >14 : 30</span></div>
                        <p className='py-2 text-sm  md:text-xl text-primary font-bold ' >Networking</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-[7rem] bg-white rounded-lg px-6 py-8 items-center my-4 '>
                    <div className='img-container flex items-center justify-center w-[100px] h-[100px] border rounded-full overflow-hidden'>
                        <FaHourglassEnd size={30} color='black' />
                    </div>
                    <div className='flex flex-col justify-center items-center md:items-start '>
                        <div className='flex space-x-3 items-center justify-center ' ><FaClock size={20} color='black' /><span className='text-gray-400 text-sm  md:text-xl font-bold ' >15 : 30</span></div>
                        <p className='py-2 text-sm  md:text-xl text-primary font-bold ' >Clôture</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
