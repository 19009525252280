import React from 'react'
import { Header2 } from '../../Common/Header2'
import meeting from '../../../Images/speedmeeting.jpg'
import dating from '../../../Images/speeddating.jpg'
import speedjob from '../../../Images/speedjob.jpg'
import { Footer } from '../../Common/Footer'

export const Networking = () => {
    return (
        <div>
            <Header2 />
            <div className='my-10' >
                <h1 className='mx-4 my-7 text-lg md:text-2xl font-semibold border-l-[4px] border-black pl-4'>Consultez les plannings de nos meetings </h1>
                <div className="grid grid-cols-1 md:grid-cols-3  w-[95%] mx-auto gap-6 md:gap-[60px] mt-10 mb-[60px]">
                    <div className=' shadow-xl bg-secondary  relative group overflow-hidden  h-[300px]   '>
                        <img className='w-full' src={meeting} alt="" />
                        <div className='absolute w-[30%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                            <p className='font-bold text-lg mb-1'>Speed</p>
                            <p className='font-bold text-lg mb-1'>business</p>
                            <p className='font-bold text-lg mb-1'>meeting</p>
                            <hr />
                            <br />
                            <p className='text-[50px]'>07</p>
                            <br />
                            <p className='tracking-wide'>FEVRIER</p>
                            <p>2023</p>
                        </div>
                        <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                         bottom-0 left-[-100%]  z-30
                         group-hover:left-0
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                            <div className='secteur-desc absolute my-6 mx-6'>
                                {/* <p className='text-white opacity-100'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, alias commodi explicabo in maiores saepe esse qui perspiciatis repudiandae praesentium dolores rem ratione beatae placeat nostrum mollitia reprehenderit doloribus officiis?</p>
                                <button className='my-1 bg-white text-black'>S'inscrire</button> */}
                            </div>
                        </div>
                    </div>
                    <div className=' shadow-xl bg-secondary  relative group overflow-hidden  h-[300px]  '>
                        <img className='w-full' src={speedjob} alt="" />
                        <div className='absolute w-[30%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                            <p className='font-bold text-lg mb-1'>Speed</p>
                            <p className='font-bold text-lg mb-1'>job</p>
                            <p className='font-bold text-lg mb-1'>meeting</p>
                            <br />
                            <p className='text-[50px]'>07</p>
                            <br />
                            <p className='tracking-wide'>FEVRIER</p>
                            <p>2023</p>
                        </div>
                        <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                         bottom-0 left-[-100%]  z-30
                         group-hover:left-0
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                            <div className='secteur-desc absolute my-6 mx-6'>
                                {/* <p className='text-white opacity-100'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, alias commodi explicabo in maiores saepe esse qui perspiciatis repudiandae praesentium dolores rem ratione beatae placeat nostrum mollitia reprehenderit doloribus officiis?</p>
                                <button className='my-1 bg-white text-black'>S'inscrire</button> */}
                            </div>
                        </div>
                    </div>
                    <div className=' shadow-xl bg-secondary  relative group overflow-hidden  h-[300px]  '>
                        <img className='w-full' src={dating} alt="" />
                        <div className='absolute w-[30%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                            <p className='font-bold text-lg mb-1'>Speed</p>
                            <p className='font-bold text-lg mb-1'>dating</p>
                            <br />
                            <p className='text-[50px]'>07</p>
                            <br />
                            <p className='tracking-wide'>FEVRIER</p>
                            <p>2023</p>
                        </div>
                        <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                         bottom-0 left-[-100%]  z-30
                         group-hover:left-0
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                            <div className='secteur-desc absolute my-6 mx-6'>
                                {/* <p className='text-white opacity-100'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, alias commodi explicabo in maiores saepe esse qui perspiciatis repudiandae praesentium dolores rem ratione beatae placeat nostrum mollitia reprehenderit doloribus officiis?</p>
                                <button className='my-1 bg-white text-black'>S'inscrire</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
