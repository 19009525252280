import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import sow from '../../../Images/sow.jpeg'
import president from '../../../Images/president.png'
import sg from '../../../Images/sg.JPG'
import rachid from '../../../Images/rachid.jpeg'

export const BureauExecutif = () => {

    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Bureau exécutif</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className=" m-10 flex flex-col md:flex-row justify-between px-12">
                <div className='mb-6 flex items-center justify-center flex-col'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                        <img className='border-b' src={president} alt="membre bureau" />
                    </div>
                    <h3 className='text-lg font-bold text-center py-3 text-black'>M. REDA ENNAJI</h3>
                    <span className='text-primary'>Président</span>
                </div>
                <div className='mb-6 flex items-center justify-center flex-col'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                        <img className='border-b mt-[-8px]' src={sow} alt="membre bureau" />
                    </div>
                    <h3 className='text-lg font-bold text-center py-3 text-black'>M. SOW ARDO</h3>
                    <span className='text-primary'>Vice Président</span>
                </div>
                <div className='mb-6 flex items-center justify-center flex-col'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                        <img className='border-b' src={sg} alt="membre bureau" />
                    </div>
                    <h3 className='text-lg font-bold text-center py-3 text-black'>M. EL ALAOUI SYRINE</h3>
                    <span className='text-primary'>Sécrétaire Général</span>
                </div>
                <div className='mb-6 flex items-center justify-center flex-col'>
                    <div className='w-[150px] h-[150px] rounded-full overflow-hidden'>
                        <img className='border-b mt-[-64px] ' src={rachid} alt="membre bureau" />
                    </div>
                    <h3 className='text-lg font-bold text-center py-3 text-black'>M. BENFARES RACHID</h3>
                    <span className='text-primary'>Trésorier</span>
                </div>
            </div>
            <Footer />
        </div>

    )
}
