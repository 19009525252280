import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import opportunite from '../../../Images/opportunite.jpg'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import OwlCarousel from 'react-owl-carousel';
import { OpportuniteAffairesRepos } from '../../../repositories/OpportunitesAffairesRepos'


export const ApporteurDaffaires = () => {
    const options = {
        items: 4,
        margin: 40,
        nav: false,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };

    return (
        <>
            <div className='bg-[#e4e4e7]'>
                <Header />
                <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                    <h1 className=' text-lg md:text-2xl  font-semibold '>Apporteur d’affaires </h1>
                    <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
                </div>
                <div className='pt-10 px-10 mb-10 grid gap-y-4 grid-cols-1 md:grid-cols-2  '>
                    <div className='md:w-[400px] md:h-[350px] order-last md:order-first  '>
                        <img src={opportunite} alt="Opportunité d'affaire" />
                    </div>
                    <div>
                        <p>
                            Notre club apporte des opportunités d’affaires clés en main à ses membres. De l’accès aux informations décisionnelles, à la recherche, à l’identification des marchés  jusqu’à la concrétisation des partenariats commerciaux.
                        </p>
                        <br />
                        <p> Soumissionnez à nos Projets Clés en main:
                            <ul className='mt-3'>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> BTP</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Génie civil</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Import-Export</li>
                                <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Industrie</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='px-6 md:mt-[-50px] mb-5'>
                    <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                        <h2 className='border-l-[4px] border-black pl-4'> Opportunités d'affaires du moment</h2>
                    </div>
                    <div className="carousels px-5">
                        <OwlCarousel className='owl-theme py-5' loop {...options}>
                            {OpportuniteAffairesRepos.map((opportunite, index) =>
                                <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                    <img className='border-b h-[80%] object-cover' src={opportunite.picture} alt={opportunite.name} />
                                    <h3 className='text-xs md:text-sm  text-center text-black mt-2'>{opportunite.name}</h3>
                                </div>
                            )}
                        </OwlCarousel>
                    </div>
                </div>
                <Footer />

            </div>

        </>
    )
}
