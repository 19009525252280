import React from 'react'
import { useRef } from 'react'
import { Header2 } from '../../Common/Header2'
import { useState } from 'react'
import btqprivilege from '../../../Images/privilegebtq.png'
import { Footer } from '../../Common/Footer'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export const BoutiquePrivilege = () => {

    const [country, setCountry] = useState('')
    const [validation, setValidation] = useState('')

    const inputs = useRef([])

    const addInputs = el => {
        if (el && !inputs.current.includes(el)) {
            inputs.current.push(el)
        }
    }


    const formRef = useRef();

    const handleForm = async (e) => {
        e.preventDefault()

        try {
            console.log((inputs.current[5].value))
            formRef.current.reset();
            setValidation("Votre demande d'inscription à la boutique privilège est en cours de traitement. Vous recevrez d'ici peu un email de validation...")

        } catch {
            setValidation(".")
        }

    }

    return (
        <div>
            <Header2 />
            <div className=" w-full">
                <img src={btqprivilege} className='h-full w-full object-cover' alt="marketplace" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 my-11 px-6 gap-6 md:gap-10">
                <div className='order-last md:order-first  '>
                    <h2 className='text-center text-lg font-semibold underline text-red-800'>Vous n’êtes pas encore inscrit ?</h2>
                    <p className='text-center my-6 font-semibold'>«  Inscrivez-vous dès  aujourd’hui…. »</p>
                    <form className="signIn-form mt-5 p-6 rounded-lg shadow-lg" onSubmit={handleForm} ref={formRef} >
                        <div className="mb-6">
                            <input ref={addInputs}
                                type="text" className='border  outline-none border-black rounded-lg p-2 w-full  bg-transparent' name="name" id="name" required placeholder='Nom' />
                        </div>
                        <div className="mb-6">
                            <input ref={addInputs}
                                type="text" className='border  outline-none border-black rounded-lg  p-2 w-full  bg-transparent' name="prenom" id="prenom" required placeholder='Prénom' />
                        </div>

                        <div className="mb-6">
                            <input ref={addInputs}
                                type="text" className='border  outline-none border-black rounded-lg p-2 w-full  bg-transparent' name="entreprise" id="entreprise" required placeholder="Nom de l'entreprise" />
                        </div>
                        <div className="mb-6">
                            <input ref={addInputs}
                                type="text" className='border  outline-none border-black rounded-lg p-2 w-full  bg-transparent' name="name" id="name" required placeholder="Fonction dans l'entreprise" />
                        </div>
                        <div className="mb-6">
                            <input ref={addInputs}
                                type="email" className='border  outline-none border-black rounded-lg p-2 w-full  bg-transparent' name="email" id="signUpEmail" required placeholder='Email professionnel' />
                        </div>
                        <div className="mb-6">
                            <PhoneInput ref={addInputs} className='border   outline-none border-black rounded-lg p-2 w-full  bg-transparent'
                                defaultCountry="MA" placeholder='Téléphone' value={country} onChange={setCountry} />
                        </div>
                        <button className='bg-red-800'>S’inscrire...</button>
                        <div className='validation-text my-8'>
                            <p className='text-slate-500 italic'> {validation} </p>
                        </div>
                    </form>

                </div>
                <div className='text-center'>
                    <h2 className='text-lg mb-6 font-semibold underline'>Mon Espace Boutique Privilège</h2>
                    <button>
                        <a href="https://eprogram.store/account/login" target="_blank" rel="noopener noreferrer">Connexion</a>
                    </button>

                </div>
            </div>
            <Footer />
        </div>
    )
}
