import React from 'react'
import { Header2 } from '../../Common/Header2'
import formations from '../../../Images/formations.png'
import atelier from '../../../Images/atelier.jpg'
import atelierR from '../../../Images/atelierR.PNG'
import { Footer } from '../../Common/Footer'
import OwlCarousel from 'react-owl-carousel';


export const Academie = () => {

  const options = {
    items: 3,
    margin: 50,
    nav: true,
    rewind: true,
    autoplay: false,
    smartSpeed: 2000,
    slideBy: 1,
    autoplayTimeout: 5000,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    }

  };

  return (
    <div>
      <Header2 />
      <div className='my-10' >
        <h1 className='mx-4 my-7 text-lg md:text-2xl font-semibold border-l-[4px] border-black pl-4'>Consultez notre programme pour devenir Talent Manager </h1>
        <div className="carousels px-12">
          <OwlCarousel className='owl-theme py-5' loop {...options} nav>
            <div className='bg-secondary  relative group overflow-hidden   h-[300px] '>
              <img className='w-full' src={atelier} alt="" />
              <div className='absolute md:w-[30%] w-[40%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                <p className='font-bold text-lg'>Coaching</p>
                <br />
                <hr />
                <p className='text-[50px]'>21</p>
                <br />
                <p className='tracking-wide'>MARS</p>
                <p>2023</p>

              </div>
              <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                         bottom-0 left-[-100%]  z-30
                         group-hover:left-0
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                <div className='secteur-desc absolute my-6 mx-6'>
                  {/* <p className='text-white opacity-100'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, alias commodi explicabo in maiores saepe esse qui perspiciatis repudiandae praesentium dolores rem ratione beatae placeat nostrum mollitia reprehenderit doloribus officiis?</p>
                  <button className='my-1 bg-white text-black'>S'inscrire</button> */}
                </div>
              </div>
            </div>
            <div className='bg-secondary relative group overflow-hidden  h-[300px] '>
              <img className='w-full' src={formations} alt="" />
              <div className='absolute md:w-[30%] w-[40%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                <p className='font-bold text-lg'>Formation</p>
                <hr />
                <br />
                <p className='text-[50px]'>21</p>
                <br />
                <p className='tracking-wide'>MARS</p>
                <p>2023</p>
              </div>
              <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                         bottom-0 left-[-100%]  z-30
                         group-hover:left-0
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                <div className='secteur-desc absolute my-6 mx-6'>
                  {/* <p className='text-white opacity-100'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, alias commodi explicabo in maiores saepe esse qui perspiciatis repudiandae praesentium dolores rem ratione beatae placeat nostrum mollitia reprehenderit doloribus officiis?</p>
                  <button className='my-1 bg-white text-black'>S'inscrire</button> */}
                </div>
              </div>
            </div>
            <div className=' bg-secondary relative group overflow-hidden  h-[300px] '>
              <img className='w-full' src={atelierR} alt="" />
              <div className='absolute md:w-[30%] w-[40%] h-[100%] top-0 opacity-80 bg-primary flex flex-col items-center justify-center text-white text-lg text-bold z-10'>
                <p className='font-bold text-lg mb-1'>Atelier</p>
                <p className='font-bold text-lg'>réfléxion</p>
                <hr />
                <br />
                <p className='text-[50px]'>21</p>
                <br />
                <p className='tracking-wide'>MARS</p>
                <p>2023</p>
              </div>
              <div className="secteur-overlay absolute bg-primary opacity-90 top-0 
                         bottom-0 left-[-100%]  z-30
                         group-hover:left-0
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                <div className='secteur-desc absolute my-6 mx-6'>
                  {/* <p className='text-white opacity-100'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, alias commodi explicabo in maiores saepe esse qui perspiciatis repudiandae praesentium dolores rem ratione beatae placeat nostrum mollitia reprehenderit doloribus officiis?</p>
                  <button className='my-1 bg-white text-black'>S'inscrire</button> */}
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <Footer />
    </div>
  )
}
