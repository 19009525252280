import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logoSvg from '../../Images/logo-removebg.png'
import user from '../../Images/user.png'
import { FaBars, FaUserCircle, FaInstagramSquare, FaFacebookSquare, FaYoutubeSquare, FaLinkedin } from 'react-icons/fa';
import { XMarkIcon } from '@heroicons/react/24/outline'


export const Header2 = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => {
        setNav(!nav)
    }
    return (
        <>
            <div className="hidden md:block top-header px-4 pt-4  w-full">
                <ul className='flex md:justify-end md:items-center space-x-4'>
                    <li>
                        <ul className='flex space-x-2'>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <FaInstagramSquare size={13} color='white' />
                                </div>
                            </li>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <FaFacebookSquare size={13} color='white' />
                                </div>
                            </li>

                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <FaYoutubeSquare size={13} color='white' />
                                </div>
                            </li>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <FaLinkedin size={13} color='white' />
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <button className='py-1 px-1 mt-[3px]' >
                            <NavLink to='/' className='flex items-center text-xs '>
                                <FaUserCircle size={14} color='white' className=' mr-2 ' />
                                Déconnexion
                            </NavLink>
                        </button>
                    </li>
                    <li className='flex flex-col items-center' >
                        <div className='w-[30px] h-[30px]'>
                            <img src={user} alt="user avatar" />
                        </div>
                        {/* <small className='text-[12px]'>Vous</small> */}
                    </li>
                </ul>
            </div>
            <div className='hidden md:block navbar  bg-[#e4e4e7] shadow-lg'>
                <div className=' px-2 py-1 w-full flex items-center justify-between'>
                    <div className="logo w-[20%]">
                        <NavLink to='/' >
                            <img className='w-100' src={logoSvg} alt="Le logo" />
                        </NavLink>
                    </div>
                    <div className="menu2 ml-6">
                        <ul className='flex space-x-6 text-sm'>
                            <NavLink to='/Marketplace' className='flex-none ' activeclassname="active" >
                                <li>Marketplace</li>
                            </NavLink>
                            <NavLink to='/Networking' className='flex-none' >
                                <li>Networking</li>
                            </NavLink>
                            <NavLink to='/opportunite-d-affaire' className='text-center ' >
                                <li>Opportunités d’affaires</li>
                            </NavLink>
                            <NavLink to='/academie' className='flex-none' >
                                <li>Académie</li>
                            </NavLink>
                            <NavLink to='/prestataires-services' className='text-center ' >
                                <li>Prestataires services</li>
                            </NavLink>
                            <NavLink to='/centrale-d-achat' className='text-center ' >
                                <li>Centrale d’achat</li>
                            </NavLink>
                            <NavLink to='/boutique-privilege' className='text-center ' >
                                <li>Boutique privilège</li>
                            </NavLink>
                            <NavLink to='/arts-et-evenements' className='text-center ' >
                                <li>Evènementiel</li>
                            </NavLink>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='mobile-nav2 navbar md:px-4 px-1 w-full md:hidden py-1 md:py-1 flex items-center justify-between'>
                <div className="logo w-[40%]">
                    <NavLink to='/' >
                        <img className='w-100' src={logoSvg} alt="Le logo" />
                    </NavLink>
                </div>
                <div className=' cursor-pointer ' onClick={handleClick} >
                    {!nav && <FaBars color='#e68106' size={25} />}
                </div>
                <ul className={!nav ? 'hidden' : 'absolute top-3 w-full   mobile-dropdown bg-primary p-3 '}>
                    <div className=' cursor-pointer ' onClick={handleClick} >
                        {nav && <XMarkIcon className='w-8 mb-2 mr-[-8px] text-white float-right ' />}
                    </div>
                    <NavLink to='/Marketplace' activeclassname="active" >
                        <li className=' '>Marketplace</li>
                    </NavLink>
                    <NavLink to='/Networking' >
                        <li>Networking</li>
                    </NavLink>
                    <NavLink to='/opportunite-d-affaire'  >
                        <li>Opportunités d’affaires</li>
                    </NavLink>
                    <NavLink to='/academie'  >
                        <li>Académie</li>
                    </NavLink>
                    <NavLink to='/prestataires-services'  >
                        <li>Prestataires services</li>
                    </NavLink>
                    <NavLink to='/centrale-d-achat' >
                        <li>Centrale d’achat</li>
                    </NavLink>
                    <NavLink to='/boutique-privilege'  >
                        <li>Boutique privilège</li>
                    </NavLink>
                    <NavLink to='/arts-et-evenements' >
                        <li>Arts et évènements</li>
                    </NavLink>
                    <li className='border-none'>
                        <button className='py-1.5 px-1 mt-[3px] bg-white text-primary w-full' >
                            <NavLink to='/' className='flex items-center justify-center text-xs '>
                                <FaUserCircle size={20} color='' className='  mr-2 ' />
                                Déconnexion
                            </NavLink>
                        </button>
                    </li>
                    <div className='flex justify-center text-primary space-x-2'>
                        <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                            <FaInstagramSquare size={18} />
                        </div>

                        <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                            <FaFacebookSquare size={18} />
                        </div>

                        <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                            <FaYoutubeSquare size={18} />
                        </div>

                        <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                            <FaLinkedin size={18} />
                        </div>
                    </div>
                </ul>
            </div>
        </>
    )
}
