import auto from '../Images/auto.jpg'
import sante from '../Images/prestataireCat/sante.jpg'
import voyage from '../Images/boutiqueP/voyage.jpg'
import immobiler from '../Images/prestataireCat/immobilier.jpg'
import spa from '../Images/prestataireCat/spa.jpg'
import personnelEntretien from '../Images/prestataireCat/personnel-entretien.PNG'
import gensdemaison from '../Images/prestataireCat/gens-maison.PNG'

export const PrestatairesListRepos = [
    {
        name: "Agence de location de voiture",
        picture: auto
    },
    {
        name: "Professionnel de santé",
        picture: sante
    },
    {
        name: "Agence de voyage",
        picture: voyage
    },
    {
        name: "Agence immobilière",
        picture: immobiler
    },
    {
        name: "Spa et bien être",
        picture: spa

    },
    {
        name: "Personnels d'entretien",
        picture: personnelEntretien
    },
    {
        name: "Gens de maison",
        picture: gensdemaison
    },

]