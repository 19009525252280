import React from 'react'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import OwlCarousel from 'react-owl-carousel';
import btp from '../../../Images/secteurs/btp.jpg'
import pharma from '../../../Images/pharma.jpg'
import assurance from '../../../Images/assurance.jpg'
import auto from '../../../Images/auto.jpg'
import { PrestatairesListRepos } from '../../../repositories/PrestatairesListRepos';

export const ReseauDePrestataires = () => {

    const options = {
        items: 4,
        margin: 40,
        nav: false,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };

    return (
        <div>
            <Header />
            <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl  font-semibold '>Réseau de prestataires </h1>
                <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
            </div>
            <div className='pt-10 px-10 mb-10 gap-y-4 grid grid-cols-1 md:grid-cols-2'>
                <div className='md:w-[400px] md:h-[320px] order-last md:order-first  '>
                    <div className="grid grid-cols-2 grid-rows-2 gap-3">
                        <img className=' h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={btp} alt="Courtier commerce" />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={assurance} alt="Courtier commerce" />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={pharma} alt="Courtier commerce" />
                        <img className='h-[130px] cursor-pointer hover:scale-125 hover:transition-all hover:duration-300' src={auto} alt="Courtier commerce" />
                    </div>
                </div>
                <div>
                    <p>
                        Nos prestataires de services, adhérents au club, accordent des prestations à des prix avantageux à tous nos membres et avec un accueil VIP.
                    </p>
                    <br />
                    <p> <strong>Rejoignez le secteur qui vous intéresse:</strong> </p>



                    <p>  Automobile- Beauté- Santé et Remise en forme -BTP Construction et Génie Civil- Immobilier -Foncier -Dépôt Vente/Cash - Aménagement et Décoration - les Fleurs, Grande Distribution - Habitat - Meubles - Hôtellerie - Restauration - Internet et Informatique - Loisirs - Prêt-à-porter et Accessoires - Services Entreprises - Services Financiers et Assurances - Services Particuliers.</p>
                    <br />

                </div>
            </div>
            <div className='px-6 md:mt-[-50px] mb-5'>
                <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                    <h2 className='border-l-[4px] border-black pl-4'> Rejoignez notre réseau de prestataires. Nous recherchons... </h2>
                </div>
                <div className="carousels px-5">
                    <OwlCarousel className='owl-theme py-5' loop {...options}>
                        {
                            PrestatairesListRepos.map((prestataireCat, index) =>
                                <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                    <img className='border-b h-[80%] object-cover ' src={prestataireCat.picture} alt={prestataireCat.name} />
                                    <h3 className='text-sm md:text-lg font-bold text-center text-black mt-2'>{prestataireCat.name}</h3>
                                </div>
                            )
                        }


                    </OwlCarousel>
                </div>
            </div>
            <Footer />
        </div>
    )
}
