import React from 'react'
import { FaEnvelope, FaPhoneSquare, FaLocationArrow } from 'react-icons/fa'


export const Footer = () => {
    return (
        <div className='footer bg-secondary px-4 pt-4 '>
            <div className='grid grid-cols-1 md:grid-cols-2 mb-1'>
                <div className="info-contact md:px-6">
                    <h2 className=' text-[28px]  mb-3 font-bold'>  Nous contacter</h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-8'>
                        <div>
                            <div className='border-b-2 border-black flex items-center text-lg space-x-4 pb-1  '>
                                <FaPhoneSquare size={20} color='chocolate' className='rotate-90' />
                                <span className=' font-semi-bold text-[15px]'>Téléphone</span>
                            </div>
                            <div className='py-2 text-[12px]'>
                                <p> <span className='underline' >Contact : </span></p>
                                <span><a href="tel:+212708368578 ">(+212) 708-368578</a></span>
                            </div>
                        </div>
                        <div>
                            <div className='border-b-2 border-black flex items-center text-lg space-x-4 pb-1  '>
                                <FaLocationArrow color='chocolate' size={20} />
                                <span className=' font-semi-bold text-[15px]'>Adresse</span>
                            </div>
                            <div className='py-2 text-[12px]'>
                                <p className='underline'>Siège social:</p>
                                <p> N°1, 1er Etage, Imm 223,
                                    <br />
                                    Quartier Hay Nahda, Rabat
                                    <br />
                                </p>

                                <p className='mt-2 underline'>Bureau:</p>
                                <p> 9K4, rue Al Najd,
                                    <br />
                                    Quartier Hay Riad, Rabat
                                    <br />
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className='border-b-2 border-black flex items-center text-lg space-x-4 pb-1  '>
                                <FaEnvelope color='chocolate' size={20} />
                                <span className=' font-semi-bold text-[15px]'>Email</span>
                            </div>
                            <div className='py-2 text-[12px] text-blue-700'>
                                <p><a className='underline' href="mailto:contact@rootsclub.ma">contact@rootsclub.ma</a></p>
                                <p><a className='underline' href="mailto:adhesion@rootsclub.ma">adhesion@rootsclub.ma</a></p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="map overflow-hidden">
                    <h2 className=' text-[28px]  mb-3 font-bold '>Localisation</h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26474.40532979566!2d-6.8734931999999995!3d33.95911075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7132b7118bfbd%3A0x2f9cad14202306ec!2sHay%20Riad%2C%20Rabat!5e0!3m2!1sfr!2sma!4v1671457238835!5m2!1sfr!2sma"
                        width="100%"
                        height="100%"
                        allowFullScreen=""
                        loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
            <hr />
            <div className=" flex justify-center items-center mt-1 ">
                <small className=' font-bold'>&copy; Roots Club 2023</small>
            </div>
        </div>
    )
}
