import React from 'react'
import formation from '../../../Images/formation.jpg'
import Header from '../../Common/Header'
import { Footer } from '../../Common/Footer'
import { FaArrowAltCircleRight, FaPlusCircle } from 'react-icons/fa'
import OwlCarousel from 'react-owl-carousel';
import { FormationsRepository } from '../../../repositories/FormationsRepository'

export const DeveloppeurDeCompetences = () => {

    const options = {
        items: 4,
        margin: 40,
        nav: false,
        rewind: true,
        autoplay: true,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    };
    return (
        <div className='bg-[#e4e4e7]'>
            <Header />
            <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                <h1 className=' text-lg md:text-2xl  font-semibold flex-none '>Développeur de compétence</h1>
                <a className='text-xs underline mt-2' href="/">Retour à l'accueil...</a>
            </div>
            <div className='pt-10 mb-10 px-10 grid gap-y-4 grid-cols-1 md:grid-cols-2  '>
                <div className='md:w-[400px] md:h-[320px] order-last md:order-first '>
                    <img src={formation} alt="Courtier commerce" />
                </div>
                <div>
                    <p>
                        La gestion des affaires est devenue complexe. Il n’y a tout simplement plus le temps d’apprendre en faisant des erreurs.
                        Nos ateliers permettent de mettre en commun réflexions et expériences sur diverses problématiques pour aider nos chefs d’entreprise à accélérer la courbe de leur apprentissage, les guider efficacement  jusqu’à leur zone de confort et atteindre la  pleine croissance de leur entreprise.
                    </p>
                    <br />
                    <p> Devenez un Talent Management grâce à nos sessions de :
                        <ul className='mt-3'>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Coaching</li>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Formation</li>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Atelier de réflexion</li>
                            <li className='flex cursor-default'>  <FaArrowAltCircleRight color='#e68106' size={16} className='mt-[5px] mr-2' /> Séminaires - Salons - Foires</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className='px-6 md:mt-[-50px] mb-5'>
                <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                    <h2 className='border-l-[4px] border-black pl-4'> Sessions à venir</h2>
                </div>
                <div className="carousels px-5">
                    <OwlCarousel className='owl-theme py-5' loop {...options} >
                        {FormationsRepository.map((formation, index) =>
                            <div className=' shadow-lg bg-secondary pb-2 h-[250px] xl:h-[300px]  relative group group-overlay cursor-pointer overflow-hidden'>
                                <img className='border-b h-[80%] object-cover' src={formation.picture} alt={formation.name} />
                                <h3 className='text-xs md:text-sm text-center text-black mt-2'>{formation.name} </h3>
                                <div className='flex items-center justify-center space-x-3 text-black' ><span className=''><FaPlusCircle size={16} className='' /> </span><span className='text-xs md:text-sm' > Stage pratique garanti {formation.advantages} </span></div>

                            </div>
                        )}
                    </OwlCarousel>
                </div>
            </div>
            <Footer />

        </div>
    )
}
