import op1 from '../Images/affaires/op1.jpeg'
import op2 from '../Images/affaires/op2.jpeg'
import op3 from '../Images/affaires/op3.PNG'
import op4 from '../Images/affaires/op4.PNG'

export const OpportuniteAffairesRepos = [
    {
        name: "Construction d'une tour haut standing de 15 étages à Dakar (Avenue Malick Sy) ",
        picture: op1
    },

    {
        name: "Carrières de sables et d’agrégats disponibles à l'exploitation ou à la vente dans plusieurs pays d'Afrique",
        picture: op2
    },
    {
        name: "Construction d'un Hôtel écologique de luxe à Dakhla",
        picture: op3
    },
    {
        name: "Réalisation sur mesure de pièces d'usure. Ci-dessus vente croc d'amarrage pour navires marchands et pétroliers",
        picture: op4
    }
]