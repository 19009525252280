import React from 'react'
import '../../Styles/header.css'
import { FaBars, FaVolumeUp, FaGraduationCap, FaCartArrowDown, FaHatWizard, FaHandHolding, FaSearch, FaHandsHelping, FaUserFriends, FaAddressCard, FaChevronDown, FaUserCircle, FaInstagramSquare, FaFacebookSquare, FaYoutubeSquare, FaLinkedin } from 'react-icons/fa';
// import logoSvg from '../../Images/logo-removebg.png'
import logoSvg from '../../Images/logoroots2.png'
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline'



export default function Header() {


    const [nav, setNav] = useState(false);
    const handleClick = () => {
        setNav(!nav)
    }

    const [navbarstyle, setNavbar] = useState('nav1');
    const listenScrollEvent = (event) => {
        if (window.scrollY <= 70) {
            return setNavbar("nav1")
        } else if (window.scrollY > 70) {
            return setNavbar("nav2")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    return (
        <>
            <div className="top-header hidden md:block md:px-4 px-1 py-1   md:py-1 pt-6 w-full">
                <ul className='flex space-x-1 md:justify-end items-center md:space-x-4'>
                    <li>
                        <ul className='flex space-x-2'>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <a href="https://www.instagram.com/rootsclubmaroc/" target="_blank" rel="noopener noreferrer"><FaInstagramSquare size={13} color='white' /></a>
                                </div>
                            </li>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <FaFacebookSquare size={13} color='white' />
                                </div>
                            </li>

                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <FaYoutubeSquare size={13} color='white' />
                                </div>
                            </li>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-primary opacity-80  hover:opacity-100 w-5 h-5 rounded-full' >
                                    <a href="https://www.linkedin.com/company/rootsclub/" target="_blank" rel="noopener noreferrer"> <FaLinkedin size={13} color='white' /></a>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className='flex-none'>
                        <button className=' pt-0 pb-1 px-1'>
                            <NavLink className='text-xs' to='/adhesion'>
                                Devenir membre
                            </NavLink>
                        </button>
                    </li>
                    <li className='flex-none'>
                        <button className='py-1 px-1 mt-[3px]' >
                            <NavLink to='/connexion' className='flex items-center text-xs '>
                                <FaUserCircle size={14} color='white' className=' hidden md:block mr-2 ' />
                                Se connecter
                            </NavLink>
                        </button>
                    </li>
                    <li >
                        <button className='hidden md:flex items-center bg-transparent text-xs text-white '>
                            <FaSearch size={15} color='white' className=' mr-2 ' />
                            Rechercher
                        </button>
                    </li>
                </ul>

            </div>
            <div className={`navbar md:px-4 px-1 w-full ${navbarstyle} py-2 md:py-1 hidden  md:flex md:items-center md:justify-between`}>
                <div className="logo w-[20%]">
                    <NavLink to='/' >
                        <img className='w-100' src={logoSvg} alt="Le logo" />
                    </NavLink>
                </div>
                <div className=" menu mr-6">
                    <ul className='space-x-6'>
                        <li className='dropdown group '>
                            <div>
                                <div className='flex font-semibold'>Roots Club    <FaChevronDown size={15} className='mt-[6px] ml-1' /></div>
                            </div>
                            <div className='dropdown-content dropdown-w3 hidden group-hover:flex  group-hover:transition-all group-hover:duration-400  group-hover:justify-between text-black'>
                                <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Présentation</span>
                                        <span><FaAddressCard size={20} className='' /></span>
                                    </div>
                                    <ul>
                                        <NavLink to='/mot-du-president'>
                                            <li>Mot du président</li>
                                        </NavLink>
                                        <NavLink to='/qui-sommes-nous'>
                                            <li>Qui sommes nous?</li>
                                        </NavLink>
                                        <NavLink to='/charte-du-club'>
                                            <li>Charte du club</li>
                                        </NavLink>
                                    </ul>
                                </div>
                                {/* <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Gouvernance</span>
                                        <span><FaUserCircle size={20} className='' /></span>
                                    </div>
                                    <ul>
                                        <NavLink to='/membres-dhonneur'>
                                            <li>Comité d'honneur</li>
                                        </NavLink>
                                        <NavLink to='/bureau-executif'>
                                            <li>Bureau exécutif</li>
                                        </NavLink>
                                        <NavLink to='/equipe-permanente'>
                                            <li>Equipe permanente</li>
                                        </NavLink>

                                    </ul>
                                </div> */}
                                <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Réseau</span>
                                        <span><FaUserFriends size={20} className='' /></span>
                                    </div>
                                    <ul>
                                        {/* <NavLink to='/prestataires'>
                                            <li>Nos prestataires</li>
                                        </NavLink> */}
                                        <NavLink to='/partenaires'>
                                            <li>Nos partenaires</li>
                                        </NavLink>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li className='dropdown group '>
                            <div className='flex font-semibold'>
                                Nos activités <FaChevronDown size={15} className='mt-[6px] ml-1' />
                            </div>
                            <div className='dropdown-content dropdown-w2 hidden group-hover:flex  group-hover:transition-all group-hover:duration-400  group-hover:justify-between text-black '>
                                <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Services</span>
                                        <span><FaHandHolding size={25} className='mt-[-10px]' /></span>
                                    </div>
                                    <ul>

                                        <NavLink to='/nos-activites/professionel-du-networking'>
                                            <li>Professionnel du networking</li>
                                        </NavLink>
                                        <NavLink to='/nos-activites/developpeur-de-competences'>
                                            <li>Développeur de compétences</li>
                                        </NavLink>
                                        <NavLink to='/nos-activites/business-angel'>
                                            <li>Business angel</li>
                                        </NavLink>
                                    </ul>
                                </div>
                                <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Négoce</span>
                                        <span><FaHandsHelping size={23} /></span>

                                    </div>
                                    <ul>
                                        <NavLink to='/nos-activites/courtier-en-commerce-international'>
                                            <li>Commerce international</li>
                                        </NavLink>
                                        <NavLink to='/nos-activites/apporteur-daffaires'>
                                            <li>Apporteur d’affaires </li>
                                        </NavLink>
                                    </ul>
                                </div>

                            </div>
                        </li>
                        <li className='dropdown group '>
                            <div className='flex font-semibold'>
                                Avantages du club <FaChevronDown size={15} className='mt-[6px] ml-1' />
                            </div>
                            <div className='dropdown-content dropdown-w3 dropdown-right hidden group-hover:flex group-hover:justify-between w-[300px]  '>
                                <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Commerces</span>
                                        <span><FaCartArrowDown size={23} /></span>

                                    </div>
                                    <ul>
                                        <NavLink to='/avantages-du-club/reseau-de-prestataires'>
                                            <li>Réseau de prestataires</li>
                                        </NavLink>
                                        <NavLink to='/avantages-du-club/centrale-d-achat'>
                                            <li>Centrale d'achats </li>
                                        </NavLink>
                                        <NavLink to='/avantages-du-club/boutique-privilège'>
                                            <li>Boutique privilège </li>
                                        </NavLink>
                                    </ul>
                                </div>
                                <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Animations</span>
                                        <span><FaHatWizard size={25} className='mt-[-10px]' /></span>
                                    </div>
                                    <ul>
                                        <NavLink to='/avantages-du-club/lieu-de-rencontre'>
                                            <li>Lieu d'échange</li>
                                        </NavLink>
                                        <NavLink to='/avantages-du-club/arts-et-evenements'>
                                            <li>Evénementiel</li>
                                        </NavLink>
                                    </ul>
                                </div>

                            </div>
                        </li>
                        <li className='dropdown group'>
                            <div className='flex font-semibold'>
                                Nos évènements <FaChevronDown size={15} className='mt-[6px] ml-1' />
                            </div>
                            <div className='dropdown-content dropdown-w3 dropdown-right hidden group-hover:flex group-hover:justify-between ] '>
                                <div>
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Academie</span>
                                        <span><FaGraduationCap size={23} /></span>
                                    </div>
                                    <ul>
                                        <li> <NavLink to="/evenements/formation">Formation</NavLink> </li>
                                        <li> <NavLink to="/evenements/seminaire">Seminaire</NavLink></li>
                                        <li> <NavLink to="/evenements/coaching">Coaching</NavLink></li>
                                        <li> <NavLink to="/evenements/conférence">Conférence</NavLink></li>
                                    </ul>
                                </div>
                                <div >
                                    <div className='flex space-x-3 items-center py-2'>
                                        <span className='font-bold'>Manifestations</span>
                                        <span><FaVolumeUp size={23} /></span>
                                    </div>
                                    <ul className='flex-none'>
                                        <li> <NavLink to="/evenements/salon">Salon</NavLink> </li>
                                        <li> <NavLink to="/evenements/foire">Foire</NavLink>  </li>
                                        <li> <NavLink to="/evenements/exposition">Exposition</NavLink> </li>
                                        <li> <NavLink to="/evenements/concours & tournois">Concours & Tournois</NavLink> </li>
                                        {/* <li> <NavLink to="/evenements/tournois">Tournois</NavLink> </li> */}
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li className='dropdown group'>
                            <div className='flex font-semibold'>
                                <NavLink to='/contact'>
                                    Contact
                                </NavLink>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mobile-nav navbar md:px-4 px-1 w-full md:hidden py-1 md:py-1 flex items-center justify-between'>
                <div className="logo w-[40%]">
                    <NavLink to='/' >
                        <img className='w-100' src={logoSvg} alt="Le logo" />
                    </NavLink>
                </div>
                <div className=' cursor-pointer ' onClick={handleClick} >
                    {!nav && <FaBars color='#e68106' size={25} />}
                </div>
                <ul className={!nav ? 'hidden' : 'absolute top-3    mobile-dropdown bg-primary p-3 '}>
                    <div className=' cursor-pointer ' onClick={handleClick} >
                        {nav && <XMarkIcon className='w-8 mb-2 mr-[-8px] text-white float-right ' />}
                    </div>
                    <li className='dropdown group mb-6 pb-3 border-b border-white w-full '>
                        <div>
                            <div className='flex justify-between font-semibold'>Roots Club    <FaChevronDown size={15} className='mt-[6px] ml-1' /></div>
                        </div>
                        <div className='dropdown-2-content dropdown-w3 py-6 px-4 hidden group-hover:flex group-hover:flex-col  group-hover:transition-all group-hover:duration-400  group-hover:justify-between text-black'>
                            <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Présentation</span>
                                    <span><FaAddressCard size={20} className='' /></span>
                                </div>
                                <ul>
                                    <NavLink to='/mot-du-president'>
                                        <li>Mot du président</li>
                                    </NavLink>
                                    <NavLink to='/qui-sommes-nous'>
                                        <li>Qui sommes nous?</li>
                                    </NavLink>
                                    <NavLink to='/charte-du-club'>
                                        <li>Charte du club</li>
                                    </NavLink>
                                </ul>
                            </div>
                            {/* <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Gouvernance</span>
                                    <span><FaUserCircle size={20} className='' /></span>
                                </div>
                                <ul>
                                    <NavLink to='/membres-dhonneur'>
                                        <li>Comité d'honneur</li>
                                    </NavLink>
                                    <NavLink to='/bureau-executif'>
                                        <li>Bureau exécutif</li>
                                    </NavLink>
                                    <NavLink to='/equipe-permanente'>
                                        <li>Equipe permanente</li>
                                    </NavLink>
                                </ul>
                            </div> */}
                            <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Réseau</span>
                                    <span><FaUserFriends size={20} className='' /></span>
                                </div>
                                <ul>
                                    <NavLink to='/partenaires'>
                                        <li>Nos partenaires</li>
                                    </NavLink>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li className='dropdown group pb-3 mb-6 border-b border-white w-full  '>
                        <div className='flex justify-between  font-semibold'>
                            Nos activités <FaChevronDown size={15} className='mt-[6px] ml-1' />
                        </div>
                        <div className='dropdown-2-content py-6 px-4 hidden group-hover:flex group-hover:flex-col  group-hover:transition-all group-hover:duration-400  group-hover:justify-between text-black '>
                            <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Services</span>
                                    <span><FaHandHolding size={25} className='mt-[-10px]' /></span>
                                </div>
                                <ul>

                                    <NavLink to='/nos-activites/professionel-du-networking'>
                                        <li>Professionnel du networking</li>
                                    </NavLink>
                                    <NavLink to='/nos-activites/developpeur-de-competences'>
                                        <li>Développeur de compétences</li>
                                    </NavLink>
                                    <NavLink to='/nos-activites/business-angel'>
                                        <li>Business angel</li>
                                    </NavLink>
                                </ul>
                            </div>
                            <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Négoce</span>
                                    <span><FaHandsHelping size={23} /></span>

                                </div>
                                <ul>
                                    <NavLink to='/nos-activites/courtier-en-commerce-international'>
                                        <li>Commerce international</li>
                                    </NavLink>
                                    <NavLink to='/nos-activites/apporteur-daffaires'>
                                        <li>Apporteur d’affaires </li>
                                    </NavLink>
                                </ul>
                            </div>

                        </div>
                    </li>
                    <li className='dropdown group pb-3 mb-6 border-b border-white w-full'>
                        <div className='flex justify-between  font-semibold'>
                            Avantages du club <FaChevronDown size={15} className='mt-[6px] ml-1' />
                        </div>
                        <div className='dropdown-2-content py-6 px-4 hidden group-hover:flex group-hover:flex-col  group-hover:justify-between w-[300px]  '>
                            <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Commerces</span>
                                    <span><FaCartArrowDown size={23} /></span>

                                </div>
                                <ul>
                                    <NavLink to='/avantages-du-club/reseau-de-prestataires'>
                                        <li>Réseau de prestataires</li>
                                    </NavLink>
                                    <NavLink to='/avantages-du-club/centrale-d-achat'>
                                        <li>Centrale d'achats </li>
                                    </NavLink>
                                    <NavLink to='/avantages-du-club/boutique-privilège'>
                                        <li>Boutique privilège </li>
                                    </NavLink>
                                </ul>
                            </div>
                            <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Animations</span>
                                    <span><FaHatWizard size={25} className='mt-[-10px]' /></span>
                                </div>
                                <ul>
                                    <NavLink to='/avantages-du-club/lieu-de-rencontre'>
                                        <li>Lieu de rencontre</li>
                                    </NavLink>
                                    <NavLink to='/avantages-du-club/arts-et-evenements'>
                                        <li>Arts et évènements </li>
                                    </NavLink>
                                </ul>
                            </div>

                        </div>
                    </li>
                    <li className='dropdown group pb-3 mb-6 border-b border-white w-full'>
                        <div className='flex justify-between  font-semibold'>
                            Nos évènements <FaChevronDown size={15} className='mt-[6px] ml-1' />
                        </div>
                        <div className='dropdown-2-content py-6 px-4 hidden group-hover:flex group-hover:flex-col group-hover:justify-between ] '>
                            <div>
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Academie</span>
                                    <span><FaGraduationCap size={23} /></span>
                                </div>
                                <ul>
                                    <li> <NavLink to="/evenements/formation">Formation</NavLink> </li>
                                    <li> <NavLink to="/evenements/seminaire">Seminaire</NavLink></li>
                                    <li> <NavLink to="/evenements/coaching">Coaching</NavLink></li>
                                    <li> <NavLink to="/evenements/conférence">Conférence</NavLink></li>
                                </ul>
                            </div>
                            <div >
                                <div className='flex space-x-3 items-center py-2'>
                                    <span className='font-bold'>Manifestations</span>
                                    <span><FaVolumeUp size={23} /></span>
                                </div>
                                <ul className='flex-none'>
                                    <li> <NavLink to="/evenements/salon">Salon</NavLink> </li>
                                    <li> <NavLink to="/evenements/foire">Foire</NavLink>  </li>
                                    <li> <NavLink to="/evenements/exposition">Exposition</NavLink> </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li className='mb-6 pb-3 border-b border-white w-full'>
                        <div className=' font-semibold'>
                            <NavLink to='/contact'>
                                Contact
                            </NavLink>
                        </div>
                    </li>
                    <li className='flex-none mb-6'>
                        <button className=' py-1.5 px-1 bg-white text-primary w-full'>
                            <NavLink className='text-xs' to='/adhesion'>
                                Devenir membre
                            </NavLink>
                        </button>
                    </li>
                    <li className='flex-none mb-6'>
                        <button className='py-1.5 px-1 mt-[3px] bg-white text-primary w-full' >
                            <NavLink to='/connexion' className='flex items-center justify-center text-xs '>
                                <FaUserCircle size={20} color='' className='  mr-2 ' />
                                Se connecter
                            </NavLink>
                        </button>
                    </li>
                    <li>
                        <ul className='flex justify-center text-primary space-x-2'>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                                    <a href="https://www.instagram.com/rootsclubmaroc/" target="_blank" rel="noopener noreferrer"><FaInstagramSquare size={18} /></a>
                                </div>
                            </li>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                                    <FaFacebookSquare size={18} />
                                </div>
                            </li>

                            <li>
                                <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                                    <FaYoutubeSquare size={18} />
                                </div>
                            </li>
                            <li>
                                <div className='icon-content flex justify-center items-center bg-white opacity-80  hover:opacity-100 w-7 h-7 rounded-full' >
                                    <a href="https://www.linkedin.com/company/rootsclub/" target="_blank" rel="noopener noreferrer"> <FaLinkedin size={18} /></a>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
        </>
    )
}
