// import company from '../Images/company.png'
import sigma from '../Images/partenaires/sigma2.png'
import eprogram from '../Images/partenaires/epro.PNG'
import boursedeCasa from '../Images/partenaires/logo-bourse-casa.png'
import kasbah from '../Images/partenaires/kasbah.png'
import saoTome from '../Images/partenaires/saoTome.png'
import cameroun from '../Images/partenaires/cameroun.jpg'
import galien from '../Images/partenaires/galien.png'
import vide from '../Images/partenaires/vide.PNG'

export const PartenersRepository = [
    {
        name: 'Ambassade de Cameroun',
        picture: cameroun,

    },
    {
        name: 'Ambassade de Sao Tomé et Principe',
        picture: saoTome,

    },
    {
        name: 'vide',
        picture: vide,

    },
    {
        name: 'vide',
        picture: vide,

    },
    {
        name: 'vide',
        picture: vide,

    },

    {
        name: 'Bourse de Casa',
        picture: boursedeCasa,

    },
    {
        name: 'Sigma assurance',
        picture: sigma,

    },
    {
        name: 'Eprogram',
        picture: eprogram,

    },
    {
        name: 'Hôtel la kasbah',
        picture: kasbah,
    },
    {
        name: 'Pharmacie Galien',
        picture: galien,
    },
    {
        name: 'vide',
        picture: vide,

    },
    {
        name: 'vide',
        picture: vide,

    },
    {
        name: 'vide',
        picture: vide,

    },
    {
        name: 'vide',
        picture: vide,

    },
    {
        name: 'vide',
        picture: vide,

    },

]
