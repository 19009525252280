import React from 'react'
import Header from '../Common/Header'
import formulaireAdhesion from '../../files/FormulaireAdhesionMembres.pdf'
import charteRootsClub from '../../files/CharteRootsClub.pdf'
import formumaireImg from '../../Images/formulaireImg.PNG'
import { Footer } from '../Common/Footer'
import charteImg from '../../Images/charte.PNG'

export const Adhesion = () => {
    return (
        <div>

            <Header />

            <div className="title py-4 px-1 md:px-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl font-semibold '>Devenir membre</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className="md:grid md:grid-cols-12 gap-6 px-3 text-center py-10">
                <div className='md:col-span-1'></div>
                <div className="md:col-span-8 text-[12px] md:text-[16px] px-4 md:px-0">
                    <div>
                        <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Membre d’honneur</h3>
                        <p className='my-6 text-justify'>
                            Notre Club d’Affaires réserve à certaines
                            personnes la qualité de Membre d&#39;honneur. Cette attribution
                            reconnait les services et contributions exceptionnels rendus par un
                            individu aussi bien au club qu’à la société civile. Le membre
                            d’honneur est élu pour une année et le titre peut être éventuellement
                            renouvelé.
                        </p>
                        <p className='my-6 text-justify'>
                            La validation des candidatures d’adhésion se fait sur dossier. Le club se réserve le droit de ne pas valider l’inscription de professionnels dont l’activité risquerait d’être surreprésentée au sein du club, dans le but de conserver une « diversité métiers ».
                        </p>
                        <p className='my-6 text-justify text-red-700'>
                            La cotisation des membres d'honneur est laissée à leur appréciation.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Membre Partenaire</h3>
                        <p className='my-6 text-justify'>
                            Notre Club d’Affaires développe des
                            partenariats avec des entreprises, des associations et/ou des
                            organismes avec lesquels nous pouvons échanger stratégiquement
                            et trouver une entente commune afin d’apporter aux autres
                            membres de notre club, de nouveaux produits et services et de
                            nouvelles opportunités de Business.
                        </p>
                        <p className='my-6 text-justify'>
                            La validation des candidatures d’adhésion se fait sur dossier. Le club se réserve le droit de ne pas valider l’inscription de professionnels dont l’activité risquerait d’être surreprésentée au sein du club, dans le but de conserver une « diversité métiers ».
                        </p>
                        <p className='my-6 text-justify text-red-700'>
                            L’adhésion pour les Membres Partenaires est de 24 000 DHS TTC/An.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Membre Adhérent
                        </h3>
                        <p className='my-6 text-justify'>
                            Des entrepreneurs au sens large du terme,
                            femmes et hommes créateurs, repreneurs ou dirigeants
                            d&#39;entreprises de tous secteurs d&#39;activité qui partagent des valeurs
                            communes, à savoir le réseau et le développement des affaires. Ils
                            participent activement aux réunions mensuelles organisées par les
                            animateurs et contribuent, par leurs expertises et leur savoir-faire, à
                            la vie du groupe et au développement du chiffre d’affaires.
                        </p>
                        <p className='my-6 text-justify'>
                            La validation des candidatures d’adhésion se fait sur dossier. Le club se réserve le droit de ne pas valider l’inscription de professionnels dont l’activité risquerait d’être surreprésentée au sein du club, dans le but de conserver une « diversité métiers ».
                        </p>
                        <p className='my-6 text-justify text-red-700'>
                            L’adhésion pour les Membres Adhérents est de 24 000 DHS TTC/An.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Membre Animateur
                        </h3>
                        <p className='my-6 text-justify'>
                            Proche de nos membres adhérents, de leurs
                            attentes et de leurs besoins, nos Animateurs sont chargés
                            d’organiser des rencontres, de créer des événements et de faire
                            intervenir des experts de renommés dans les domaines utiles.
                            Restant toujours à l’écoute de nos adhérents, ils doivent préserver
                            un esprit d’équipe autour du concept de ROOTS CLUB, à savoir
                            Partage, Convivialité, Entraide et Valeurs Humaines ».
                        </p>
                        <p className='my-6 text-justify'>
                            La validation des candidatures d’adhésion se fait sur dossier. Le club se réserve le droit de ne pas valider l’inscription de professionnels dont l’activité risquerait d’être surreprésentée au sein du club, dans le but de conserver une « diversité métiers ».
                        </p>
                        <p className='my-6 text-justify text-red-700'>
                            L’adhésion pour les Membres Animateurs est de 7 200 DHS TTC/An.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Membre Intervenant
                        </h3>
                        <p className=' my-6 text-justify'>
                            Consultant, coach ou expert, nos
                            Intervenants travaillent en concert avec nos membres Animateurs,
                            et sont sélectionnés pour leurs qualités humaines et leur fort capital
                            expérience dans leur domaine de compétence.
                        </p>
                        <p className='my-6 text-justify'>
                            La validation des candidatures d’adhésion se fait sur dossier. Le club se réserve le droit de ne pas valider l’inscription de professionnels dont l’activité risquerait d’être surreprésentée au sein du club, dans le but de conserver une « diversité métiers ».
                        </p>
                        <p className='my-6 text-justify text-red-700'>
                            L’adhésion pour les Membres Intervenants est de 7 200 DHS TTC/An.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Membre Délégué
                        </h3>
                        <p className=' my-6 text-justify'>
                            Représentant territorial, le délégué dispose
                            d’une lettre de mission spécifique pour promouvoir l’image et la notoriété de
                            ROOTS CLUB et étendre aussi bien notre réseau de partenariats que
                            celui de notre réseau d’adhérents.
                        </p>
                        <p className='my-6 text-justify'>
                            La validation des candidatures d’adhésion se fait sur dossier. Le club se réserve le droit de ne pas valider l’inscription de professionnels dont l’activité risquerait d’être surreprésentée au sein du club, dans le but de conserver une « diversité métiers ».
                        </p>
                        <p className='my-6 text-justify text-red-700'>
                            L’adhésion pour les Membres Délégués est de 12 000 DHS TTC/An.
                        </p>
                    </div>
                    <div>
                        <h3 className='text-sm md:text-xl mx-6 md:mx-32 leading-[44px] text-gray-500 border-b-2 font-bold  border-primary uppercase'>Membre Prestataire
                        </h3>
                        <p className=' my-6 text-justify'>
                            Ayant un statut de Commerçant, le Prestataire
                            propose des produits et services à des prix compétitifs à notre
                            communauté d’adhérents.
                        </p>
                        <p className='my-6 text-justify'>
                            La validation des candidatures d’adhésion se fait sur dossier. Le club se réserve le droit de ne pas valider l’inscription de professionnels dont l’activité risquerait d’être surreprésentée au sein du club, dans le but de conserver une « diversité métiers ».
                        </p>
                        <p className='my-6 text-justify text-red-700'>
                            L’adhésion pour les Membres Prestataires est de  4 200 DHS TTC/An.
                        </p>
                    </div>
                </div>
                <div className='md:col-span-3 border-l border-slate-400'>
                    <div className="flex justify-between ">
                        <div className=' my-6 text-lg flex justify-center flex-col'>
                            <div className='w-[90%] m-auto'>
                                <a href={formulaireAdhesion} rel='noreferrer' target='_blank'>
                                    <img className='w-[150px]' src={formumaireImg} alt="Formulaire d'adhesion" />
                                </a>
                                <div className="flex space-x-1 ml-3">
                                    <button className='my-3 w-[50px]' ><a href={formulaireAdhesion} target="_blank" rel='noreferrer' >Fr </a></button>
                                    {/* <button className='my-3 w-[50px]' ><a href={formulaireAdhesion} target="_blank" rel='noreferrer' >ENG </a></button> */}
                                </div>

                            </div>
                        </div>
                        <div className=' my-6 text-lg flex justify-center flex-col items-center'>
                            <div className='w-[90%] m-auto'>
                                <a href={formulaireAdhesion} rel='noreferrer' target='_blank'>
                                    <img className='w-[150px]' src={charteImg} alt="Charte de Roots Club" />
                                </a>
                                <div className='flex space-x-1 justify-center items-center'>
                                    <button className='my-3 w-[50px]' ><a href={charteRootsClub} target="_blank rel='noreferrer'">FR</a></button>
                                    {/* <button className='my-3 w-[50px]' ><a href={charteRootsClub} target="_blank rel='noreferrer'">ENG</a></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-[90%] m-auto'>
                        <p className=' text-sm'> Envoyez les formulaires dûment remplis à l'adresse : <a className='underline text-blue-700' href="mailto:adhesion@rootsclub.ma">adhesion@rootsclub.ma</a></p>
                        <br />
                        <p className=' text-sm'> Pour plus d'information, <a className='text-primary font-bold' target='_blank' href="/contact">contactez nous.</a></p>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    )
}
