import React from 'react'
import OwlCarousel from 'react-owl-carousel';


import eventpic1 from '../../Images/lancementRoots/eventpic1.jpg'
import eventpic2 from '../../Images/lancementRoots/eventpic2.jpg'
import eventpic3 from '../../Images/lancementRoots/eventpic3.jpg'
import eventpic4 from '../../Images/lancementRoots/eventpic4.jpg'
import eventpic5 from '../../Images/lancementRoots/eventpic5.jpg'
import eventpic6 from '../../Images/lancementRoots/eventpic6.jpg'
import eventpic7 from '../../Images/lancementRoots/eventpic7.jpg'
import eventpic8 from '../../Images/lancementRoots/eventpic8.jpg'

import beaute1 from '../../Images/actualites/beaute1.jpeg'
import beaute2 from '../../Images/actualites/beaute2.jpeg'
import beaute3 from '../../Images/actualites/beaute3.jpeg'
import beaute4 from '../../Images/actualites/beaute4.jpeg'
import beaute5 from '../../Images/actualites/beaute5.jpeg'
import beaute6 from '../../Images/actualites/beaute6.jpeg'
import beaute7 from '../../Images/actualites/beaute7.jpeg'
import beaute8 from '../../Images/actualites/beaute8.jpeg'

import beaute9 from '../../Images/actualites/beaute9.jpeg'
import beaute10 from '../../Images/actualites/beaute10.jpeg'
import beaute11 from '../../Images/actualites/beaute11.jpeg'
import beaute12 from '../../Images/actualites/beaute12.jpeg'
import summer from '../../Images/actualites/summer1.PNG'
import summerPg from '../../Images/actualites/summerPg.PNG'
import summerResto from '../../Images/actualites/summerResto.PNG'


export const LastActualities = () => {
    const options = {
        items: 1,
        margin: 40,
        rewind: true,
        autoplay: false,
        smartSpeed: 2000,
        slideBy: 1,
        autoplayTimeout: 10000,
        animateOut: 'fadeOut',
        dots: false,
    };
    return (
        <div className='actualites actualites-h my-10 px-6'>
            <div className='title mb-6 text-lg md:text-[28px] font-bold'>
                <h2 className='border-l-[4px] border-black pl-4'> Les dernières actualités</h2>
            </div>
            <div className=''>
                <OwlCarousel className='owl-theme md:py-5' loop {...options} >
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4' >
                        <div className='w-full h-[700px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] md:object-right ' src={summer} alt="" />
                        </div>
                        <div className='col-span-2 w-full h-[700px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] ' src={summerPg} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Programme du Summer Camp 2023 </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4' >
                        <div className='w-full h-[720px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] md:object-right ' src={summer} alt="" />
                        </div>
                        <div className='col-span-2 w-full h-[720px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] ' src={summerResto} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Menu de Restauration du Summer Camp 2023 </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4' >
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] md:object-right ' src={eventpic7} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] ' src={eventpic8} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4' >
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%]' src={eventpic1} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] object-top ' src={eventpic2} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                        {/* <div className=' w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='h-full object-cover object-left' src={boursecasa} alt="" />
                            <div className="secteur-overlay absolute bg-[#EB9F04] top-0 
                         bottom-0 left-0 right-0 opacity-0
                         group-hover:opacity-80
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                                <div className='secteur-desc absolute my-6 mx-6'>
                                    <p className='text-white font-bold text-xl'>
                                        Conférence : Présentation de notre partenaire institutionnel de Casablanca et de ses solutions
                                        de financement pour les entreprises africaines par le marché des capitaux.
                                        <br />
                                        <br />
                                        Mercredi 15 Mars 2023
                                        <br /> <br />
                                    </p>
                                    <button className='bg-white text-black mt-4'>Voir Plus</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4' >
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%]' src={eventpic3} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%]' src={eventpic4} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4' >
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] object-right ' src={eventpic5} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='object-cover h-[85%] object-top ' src={eventpic6} alt="" />
                            <p className='text-center md:text-xl font-bold text-black mt-2' >Retour sur l'évènement du Mercredi 08 Février 2023: Lancement de Roots Club </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4' >
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <div className="grid grid-cols-2 gap-3">
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute1} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >189,99 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute2} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >419,99 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-fullh h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <div className="grid grid-cols-2 gap-3">
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute3} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >413,99 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute4} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >340,00 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4' >
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <div className="grid grid-cols-2 gap-3">
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute5} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >515,00 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute6} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >359,99 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <div className="grid grid-cols-2 gap-3">
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute7} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >198,00 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute8} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >347,00 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4' >
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <div className="grid grid-cols-2 gap-3">
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute9} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >160.40 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[70%] object-right ' src={beaute10} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >138.80 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <div className="grid grid-cols-2 gap-3">
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[330px] object-right ' src={beaute12} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >157.80 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                                <div className='overflow-hidden'>
                                    <img className='object-cover h-[330px] object-center ' src={beaute11} alt="" />
                                    <p className='text-center text-red-800 font-bold  mt-2' >359.99 DHS
                                        <br />
                                        Offre spéciale à l'occasion de la journée de la femme.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-4  '>
                        <div className="grid grid-cols-2 gap-4">
                            <div className='h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                                <img className='h-full object-cover object-left' src={match1} alt="" />
                                <div className="secteur-overlay absolute bg-[#EB9F04] top-0 
                         bottom-0 left-0 right-0 opacity-0
                         group-hover:opacity-80
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                                    <div className='secteur-desc absolute my-6 mx-6'>
                                        <p className='text-white font-bold text-xl'>
                                            Match Champions League Jeudi 16 fevrier 2023 Barça vs Man United
                                            <br />
                                            <br />
                                            Places VIP Balcony - 750 euros
                                            <br />
                                            Place VIP Box - 600 euros
                                            <br />
                                        </p>
                                        <button className='bg-white text-black mt-4'>Voir Plus</button>
                                    </div>
                                </div>
                            </div>
                            <div className='h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                                <img className='h-full object-cover object-left' src={match2} alt="" />
                                <div className="secteur-overlay absolute bg-[#EB9F04] top-0 
                         bottom-0 left-0 right-0 opacity-0
                         group-hover:opacity-80
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                                    <div className='secteur-desc absolute my-6 mx-6'>
                                        <p className='text-white font-bold text-xl'>
                                            Match Champions League Jeudi 16 fevrier 2023  Barça vs Man United
                                            <br />
                                            <br />
                                            Places VIP Balcony - 750 euros
                                            <br />
                                            Place VIP Box - 600 euros
                                            <br />
                                        </p>
                                        <button className='bg-white text-black mt-4'>Voir Plus</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='h-[450px] overflow-hidden relative group group-overlay cursor-pointer'>
                            <img className='h-full object-cover' src={match4} alt="" />
                            <div className="secteur-overlay absolute bg-[#EB9F04] top-0 
                         bottom-0 left-0 right-0 opacity-0
                         group-hover:opacity-80
                         w-[100%]
                         group-hover:transition-all
                         group-hover:duration-300
                         ">
                                <div className='secteur-desc absolute my-6 mx-6'>
                                    <p className='text-white font-bold text-xl'>
                                        Match Champions League  Jeudi 16 fevrier 2023
                                        <br />  Barça vs Man United
                                        <br />
                                        <br />
                                        Places VIP Balcony - 750 euros
                                        <br />
                                        Place VIP Box - 600 euros
                                        <br />
                                    </p>
                                    <button className='bg-white text-black mt-4'>Voir Plus</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </OwlCarousel>
            </div>
        </div>
    )
}
