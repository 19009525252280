import meeting from '../Images/meeting.jpg'

import sigma from '../Images/events/sigma2.PNG'
import bourseCasa from '../Images/events/bourse-casa2.PNG'
import beachV from '../Images/events/beach-v.jpg'
import env from '../Images/events/env.jpg'
import foire from '../Images/events/foire.jpg'


import assurance from '../Images/formations/assurance.jpg'
import auxilliaireVie from '../Images/formations/auxilliaireVie.jpeg'
import hotelerie from '../Images/formations/hotelerie.jpeg'
import entreprenariat from '../Images/formations/entreprenariat.jpg'
import powerbi from '../Images/formations/powerbi.jpg'
import evenementiel from '../Images/formations/evenementiel.PNG'


export const EventsRepos = [
    {
        jour: 8,
        mois: 'FEVRIER',
        annee: 2023,
        title: "Programme d'assurance pour diplomates, les entrepreneurs et étudiants subsahariens",
        pageTitle: 'Nos conférences',
        description: "Présentation de notre partenaire courtier en assurance toutes branches et de son programme de couverture sur mesure pour les diplômates, entrepreneurs et les étudiants subsahariens.",
        category: "conférence",
        picture: sigma

    },
    // {
    //     jour: 22,
    //     mois: 'FEVRIER',
    //     annee: 2023,
    //     title: "Salon professionnel de l'équipement militaire (EPY).",
    //     pageTitle: 'Nos salons',
    //     description: "Présentation de nos partenaires équipementiers et de leur gamme d'uniformes militaires.",
    //     category: "salon",
    //     picture: salonMilitaire
    // },
    {
        jour: 15,
        mois: 'MARS',
        annee: 2023,
        title: "Financement des entreprises africaines par le marché des capitaux.",
        pageTitle: 'Nos conférences',
        description: "Présentation de notre partenaire institutionnel de Casablanca et de ses solutions de financement pour les entreprises africaines par le marché des capitaux.",
        category: "conférence",
        picture: bourseCasa

    },
    {
        jour: 27,
        mois: 'AVRIL',
        annee: 2023,
        title: 'Introduction et développement de la Junior Entreprise Marocaine en Afrique.',
        pageTitle: 'Nos séminaires',
        description: "Présentation de notre partenaire Junior Entreprise et de son modèle économique en Afrique.",
        category: "seminaire",
        picture: meeting
    },
    {
        jour: '2O-27',
        mois: 'MAI',
        annee: 2023,
        title: "Foire internationnale de l'entreprenariat Africain.",
        pageTitle: "Foires",
        description: "Foire pour la promotion de l'entreprenariat féminin et des produits Africains.",
        category: "foire",
        picture: foire
    },
    {
        jour: 27,
        mois: 'MAI',
        annee: 2023,
        title: 'Coaching',
        pageTitle: 'Nos Coachings',
        category: 'caoaching',
        picture: '../Images/meeting.jpg'
    },
    {
        jour: 21,
        mois: 'JUIN',
        annee: 2023,
        title: "Concours des jeunes talents des missions d'enseignement",
        pageTitle: 'Nos Concours',
        description: "Concours artistique pour la promotion des jeunes talents au profit des élèves des missions d'enseignement au Maroc autour des problèmes environnementaux et de leurs solutions pour le bien-être de notre planète.",
        category: 'concours & tournois',
        specificCat: 'concours',
        picture: env
    },
    {
        jour: '21-23',
        mois: 'JUILLET',
        annee: 2023,
        title: 'Tournoi beach volley',
        pageTitle: 'Tournois ',
        description: "Organisation d'un tournoi de beach volley au profit des membres des missions diplômatiques accréditées au Maroc.",
        category: "concours & tournois",
        specificCat: 'tournois',
        picture: beachV
    },

    {
        jour: '01',
        mois: 'MARS',
        annee: 2023,
        title: 'Formation technico-commerciale en assurance',
        pageTitle: 'formations ',
        description: "Formation technico-commerciale en assurance + stage pratique garanti",
        category: "formation",
        advantage: " + Stage pratique garanti",
        picture: assurance
    },
    {
        jour: '01',
        mois: 'MARS',
        annee: 2023,
        title: "Formation aux métier de l'hôtellerie  ",
        pageTitle: 'formations ',
        description: "Formation aux métier de l'hôtellerie + stage pratique garanti ",
        category: "formation",
        advantage: " + Stage pratique garanti",
        picture: hotelerie
    },
    {
        jour: '01',
        mois: 'MARS',
        annee: 2023,
        title: "Formation aux métiers d'auxiliaire de vie ",
        pageTitle: 'formations ',
        description: "Formation aux métiers d'auxiliaire de vie + stage pratique garanti ",
        category: "formation",
        advantage: " + Stage pratique garanti",
        picture: auxilliaireVie
    },
    {
        jour: '01',
        mois: 'MARS',
        annee: 2023,
        title: "Formation à l'entreprenariat ",
        pageTitle: 'formations ',
        description: "Formation à l'entreprenariat + stage pratique garanti  ",
        category: "formation",
        advantage: " + Stage pratique garanti & création d'AGR",
        picture: entreprenariat
    },
    {
        jour: '01',
        mois: 'MARS',
        annee: 2023,
        title: "Formation analyste Power BI",
        pageTitle: 'formations ',
        description: "Formation analyste Power BI",
        category: "formation",
        picture: powerbi
    },
    {
        jour: '01',
        mois: 'MARS',
        annee: 2023,
        title: "Hôtesse d'accueil, Evènementiel et Entreprise ",
        pageTitle: 'formations ',
        description: "Hôtesse d'accueil, Evènementiel et Entreprise ",
        category: "formation",
        picture: evenementiel
    },





]

