import React from 'react'
import { Header2 } from '../../Common/Header2'
import aero from '../../../Images/aero.jpg'
import { NavLink } from 'react-router-dom'
import { Footer } from '../../Common/Footer'
import pharma from '../../../Images/pharma.jpg'
import chimie from '../../../Images/chimie.jpg'
import assurance from '../../../Images/assurance.jpg'
import telecom from '../../../Images/telecom.jpg'
import auto from '../../../Images/auto.jpg'
import manufac from '../../../Images/manufac.jpg'
import entertainment from '../../../Images/entertainment.jpg'
import distribution from '../../../Images/distribution.jpg'
import btp from '../../../Images/secteurs/btp.jpg'
import medias from '../../../Images/medias.PNG'
import transport from '../../../Images/transport.PNG'
import enseignement from '../../../Images/enseignement.PNG'
import serviceP from '../../../Images/serviceP.PNG'
import agro from '../../../Images/agroalimentaire.PNG'


export const OportuniteAffaires = () => {
    return (
        <div >
            <Header2 />
            <h1 className='mx-4 my-7 text-lg md:text-2xl font-semibold border-l-[4px] border-black pl-4'>Consultez et répondez à nos offres de marchés clé en main </h1>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-x-8 gap-y-4 mx-7  mb-9 opportunies ">
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px] relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover' src={aero} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Aéronautique</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] md:h-[110px] pb-2  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover' src={auto} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Automobile</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover' src={assurance} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Banque Assurance</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={chimie} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Energie chimie</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={agro} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Agro-alimentaire</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={pharma} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Pharmaceutique</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={manufac} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Manufacturing</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={entertainment} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Entrainement</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover' src={distribution} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Grande distribution</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={telecom} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Télécommunications</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={medias} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Medias</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={btp} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center text-black'>BTP</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover object-top ' src={transport} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Transport</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={serviceP} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Service public</h3>
                </div>
                <div className=' shadow-lg bg-secondary md:w-[80%] pb-2 md:h-[110px]  relative group group-overlay cursor-pointer overflow-hidden'>
                    <img className='border-b object-cover ' src={enseignement} alt="Le logo" />
                    <h3 className='text-sm font-bold text-center  text-black'>Enseignement</h3>
                </div>

            </div>
            <h2 className='mx-4 my-7 text-lg md:text-2xl font-semibold border-l-[4px] border-black pl-4'>Vous avez besoin d’un Business Angel ? Contactez-nous </h2>
            <div className='w-[90%] m-auto mb-10 '>
                <form className="signIn-form mt-5 p-6 rounded-lg shadow-lg">
                    <div className="mb-3">
                        <label className='block mb-3' htmlFor="name">Votre Nom</label>
                        <input
                            type="text" className='border border-black bg-transparent p-2 outline-none w-full rounded-lg' name="name" id="name" required placeholder='Nom' />
                    </div>
                    <div className="mb-3">
                        <label className='block mb-3' htmlFor="SignINEmail">Email</label>
                        <input
                            type="email" className='border border-black p-2 bg-transparent outline-none w-full rounded-lg' name="email" id="Email" required placeholder='Email' />
                    </div>
                    <div className="mb-6">
                        <label className='block mb-3' htmlFor="sujet" >Sujet</label>
                        <input
                            type="text" className='border border-black p-2 bg-transparent outline-none w-full rounded-lg' name="sujet" id="sujet" required placeholder='Sujet' />
                    </div>
                    <div className="mb-6">
                        <label className='block mb-3' htmlFor="sujet" >Message</label>
                        <textarea className='border border-black p-2 w-full bg-transparent rounded-lg outline-none' name="message" id="message" cols="30" rows="10" required placeholder='Message'></textarea>
                    </div>
                    <button>
                        <NavLink to='/' >
                            Envoyer
                        </NavLink>
                    </button>
                </form>
            </div>
            <Footer />
        </div>

    )
}
