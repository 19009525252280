import React from 'react'
import Header from '../Common/Header'
import { Footer } from '../Common/Footer'
import { FaEnvelope, FaPhoneSquare, FaLocationArrow } from 'react-icons/fa'


export const Contact = () => {
    return (
        <div>

            <Header />
            <div className="title p-4 bg-[#a0a0a1] flex justify-between">
                <h1 className='text-lg md:text-2xl font-semibold '>Contact</h1>
                <a className='text-xs underline mt-3' href="/">Retour à l'accueil...</a>
            </div>
            <div className=' py-10 px-6 md:grid md:grid-cols-12 '>
                <div className='col-span-4 hidden md:block'>
                    <h2 className='text-xl text-primary'>Rejoignez-nous:</h2>
                    <div className='bg-[#e4e4e7] mt-4 grid grid-cols-1 mb-1'>
                        <div className="info-contact py-3">
                            <div className='border-b-2 border-gray-300 flex items-center text-lg space-x-4 pb-1  '>
                                <FaPhoneSquare size={20} color='chocolate' className='rotate-90' />
                                <span className='text-primary font-semi-bold'>Téléphone</span>
                            </div>
                            <div className='py-2 text-sm'>
                                <p> <span className='underline' >Contact : </span></p>
                                <span><a href="tel:+212708368578 ">(+212) 708-368578</a></span>
                            </div>
                            <br />

                            <div className='border-b-2 border-gray-300 flex items-center text-lg space-x-4 pb-1  '>
                                <FaLocationArrow color='chocolate' size={20} />
                                <span className='text-primary font-semi-bold'>Adresse</span>
                            </div>
                            <div className='py-2 text-[12px]'>
                                <p className='underline'>Siège social:</p>
                                <p> N°1, 1er Etage, Imm 223,
                                    <br />
                                    Quartier Hay Nahda, Rabat
                                    <br />
                                </p>

                                <p className='mt-2 underline'>Bureau:</p>
                                <p> 9K4, rue Al Najd,
                                    <br />
                                    Quartier Hay Riad, Rabat
                                    <br />
                                </p>
                                <br />
                            </div>
                            <div className='border-b-2 border-gray-300 flex items-center text-lg space-x-4 pb-1  '>
                                <FaEnvelope color='chocolate' size={20} />
                                <span className='text-primary font-semi-bold'>Email</span>
                            </div>
                            <div className='py-2 text-sm text-blue-700'>
                                <p><a className='underline' href="mailto:contact@rootsclub.ma">contact@rootsclub.ma</a></p>
                                <p><a className='underline  ' href="mailto:adhesion@rootsclub.ma">adhesion@rootsclub.ma</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="map overflow-hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26474.40532979566!2d-6.8734931999999995!3d33.95911075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7132b7118bfbd%3A0x2f9cad14202306ec!2sHay%20Riad%2C%20Rabat!5e0!3m2!1sfr!2sma!4v1671457238835!5m2!1sfr!2sma"
                            width="300"
                            height="200"
                            allowFullScreen=""
                            loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
                <div className='col-span-8 md:ml-10 border-l-[1px] px-3  border-gray-300'>
                    <h2 className='text-xl text-primary'>Contactez-nous</h2>
                    <form className='mt-4'>
                        <div className="mb-6">
                            <label className='block mb-3' htmlFor="Contactnom">Nom</label>
                            <input
                                type="text" className='border border-black bg-transparent rounded-lg p-2 w-full outline-0 ' name="name" id="name" required placeholder='Nom' />
                        </div>
                        <div className="mb-6">
                            <label className='block mb-3' htmlFor="Contactemail" >Email</label>
                            <input
                                type="email" className='border border-black bg-transparent rounded-lg p-2 w-full outline-0 ' name="email" id="email" required placeholder='Email' />
                        </div>
                        <div className="mb-6">
                            <label className='block mb-3' htmlFor="Contactsujet" >Sujet</label>
                            <input
                                type="text" className='border border-black bg-transparent rounded-lg p-2 w-full outline-0 ' name="sujet" id="sujet" required placeholder='Sujet' />
                        </div>
                        <div className="mb-6">
                            <label className='block mb-3' htmlFor="Contactmessage" >Message</label>
                            <textarea className='border border-black bg-transparent rounded-lg p-2 w-full outline-0 ' name="message" id="message" cols="30" rows="10" required placeholder='Message'></textarea>
                        </div>
                        <button>Envoyer</button>
                    </form>
                </div>
            </div>
            <div className='md:hidden'>
                <Footer />
            </div>
        </div>

    )
}
